import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";

export class ExportFiles {
    constructor(
        public id: number,
        public fileName: string,
        public countAll: number,
        public countSuccessful: number,
        public hash: string,
        public createTime: Date
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class ExportFilesAdapter implements Adapter<ExportFiles> {
    adapt(item: any): ExportFiles {
        return new ExportFiles(
            item.id,
            item.fileName,
            item.countAll,
            item.countSuccessful,
            item.hash,
            new Date(item.createTime * 1000)
        )
    }
}