import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";

export class DocumentUpdate {
    constructor(
        public id: number,
        public files: File[] = [],
        public title: string = "",
        public description: string  = "",
        public comment: string = ""
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class DocumentUpdateAdapter implements Adapter<DocumentUpdate> {
    adapt(item: any): DocumentUpdate {
        return new DocumentUpdate(
            item.id,
            item.files,
            item.title,
            item.description,
            item.comment || ""
        )
    }
}