import { Component, ViewChild } from '@angular/core';
import { NgForm, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NGXToastrService } from 'app/components/extra/toastr/toastr.service';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent {

  @ViewChild('f') loginForm: NgForm;

    public email: string;
    public password: string;
    public error: string;

  loginFormSubmitted = false;
  isLoginFailed = false;

  //loginForm = new UntypedFormGroup({
    //username: new UntypedFormControl('guest@apex.com', [Validators.required]),
    //password: new UntypedFormControl('Password', [Validators.required]),
    //rememberMe: new UntypedFormControl(true)
  //});


  constructor(private router: Router, 
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private toast: NGXToastrService, 
    private translate: TranslateService) {
      
      const savedLanguage = localStorage.getItem('appLanguage');
      const language = savedLanguage ? savedLanguage : 'es';
      this.translate.use(language);
  }

  /*get lf() {
    return this.loginForm.controls;
  }*/

  // On submit button click
  onSubmit() {
    /*this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    /*this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

      this.authService.signinUser(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.router.navigate(['/dashboard/dashboard1']);
        },
        (err) => {
          this.isLoginFailed = true;
          this.spinner.hide();
          console.error('error:', err);
        }
      );*/
      const emailToSend = this.email.toLowerCase();
      this.authService.signinUser(emailToSend, this.password)
        .pipe(first())  
        .subscribe( {
            next: () => {
                this.router.navigate(['/flats/allflats']);
            },
            error: error => {
                this.error = error;
                console.log(error);
                //this.toast.err102WrongData();
            }
        }
            //result => this.router.navigate(['/dashboard/dashboard1'], { relativeTo: this.route.parent }),
            //err => this.toast.err102WrongData()
        );
  }

  onForgotPassword() {
    this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
  }

}
