import { Injectable } from "@angular/core";
import { TransactionStatus } from "../enums/transactionStatus";
import { Adapter } from "app/core/interfaces/adapter";

export class Transaction {
    constructor(
        public id: number,
        public sum?: number,
        public description?: number,
        public internalFlatId?: number,
        public paymentTime?: Date,
        public createTime?: Date,
        public updateTime?: Date,
        public status?: TransactionStatus,
        public isFlatBalance: boolean = false, 

        public exportFilesId?: number,
        public flatId?: number
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class TransactionAdapter implements Adapter<Transaction> {
    adapt(item: any): Transaction {
        return new Transaction(
            item.id,
            item.sum,
            item.description,
            item.internalFlatId,
            new Date(item.paymentTime * 1000),
            new Date(item.createTime * 1000),
            new Date(item.updateTime * 1000),
            item.status,
            item.isFlatBalance !== undefined ? item.isFlatBalance : false,
            item.exportFilesId,
            item.flatId
        )
    }
}