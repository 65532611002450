import { Adapter } from "app/core/interfaces/adapter";
import { ExpensesStatus } from "../enums/expensesStatus";
import { ExpensesType } from "../enums/expensesType";
import { Injectable } from "@angular/core";

export class ExpensesUpdate {
    constructor(
        public files: File[] = [],
        public id: number,
        public type: ExpensesType,
        public status: ExpensesStatus,
        public updateTime?: number,
        public startDate?: number,
        public value?: number,
        public accountNumber?: string,
        public comment?: string,
        public isSend?: boolean
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class ExpensesUpdateAdapter implements Adapter<ExpensesUpdate> {
    adapt(item: any): ExpensesUpdate {
        return new ExpensesUpdate(
            item.files,
            item.id,
            item.type,
            item.status,
            item.updateTime,
            item.startDate,
            item.value,
            item.accountNumber,
            item.comment || "",
            item.isSend ?? false
        )
    }
}