export class FlatCreate{
    constructor(
        public title?: string,
        public description: string = "",
        public city?: string,
        public street?: string,
        public building?: string,
        public apartment?: string,
        public postcode?: string,
        public internalId?: number,
        public accountNumber?: string,
        public accountNumber2?: string,
        public accountNumber3?: string,
        public balance?: number,
        public price?: number,
        public size?: number,
        public countRooms?: number,
        public deposit?: number,
        public adminFees?: number,
        public floor?: number,
        public hasBalcony?: boolean,
        public hasTerrace?: boolean,
        public countParkingSpots?: number,
        public petAllowed?: boolean,
        public yearBuilt?: number,
        public landlordId?: number
    ){}
}