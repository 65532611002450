import { Component, OnInit } from "@angular/core";
import { LandlordService } from "./landlord.service";
import { Landlord, LandlordAdapter } from "app/models/landlord/landlord";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { FlatService } from "app/flat/flat.service";
import { Flat } from "app/models/flat/flat";
import { InvoiceService } from "app/flat/invoice.service";
import { Invoice } from "app/models/invoice/invoice";
import { UserStatus } from "app/models/enums/userStatus";
import { FlatActualContract } from "app/models/flat/flatContract";
import { NgForm } from "@angular/forms";

@Component( {
    selector: 'app-root',
    templateUrl: './profile-landlord.component.html',
    providers: [LandlordService, FlatService, InvoiceService]
})
export class ProfileLandlordComponent implements OnInit {
    
    item: Landlord;
    flats: FlatActualContract[];
    invoices: Invoice[];
    userStatus: string[];
    
    formSubmitted = false;

    constructor(private landlordService: LandlordService,
                private flatService: FlatService,
                private invoiceService: InvoiceService,
                private route: ActivatedRoute,
                private router: Router,
                private adapter: LandlordAdapter,
                private toastr: ToastrService,
                private translate: TranslateService) {

    }
    
    ngOnInit(): void {
        let id: number = Number(this.route.snapshot.paramMap.get("id"));
        this.getById(id);
        this.loadFlats(id);
        this.loadInvoices(id);
        this.userStatus = Object.keys(UserStatus);
    }

    private getById(id: number) {
        this.landlordService.getById(id).subscribe((data: Landlord) => {
            this.item = data;
        });
    }

    private loadFlats(id: number) {
        this.flatService.getAllWithActualContractByLandlordId(id).subscribe((data: FlatActualContract[]) => {
            this.flats = data;
        });
    }

    private loadInvoices(id: number) {
        this.invoiceService.getAllByLandlordId(id).subscribe((data: Invoice[]) => {
            this.invoices = data;
            //this.balance = this.invoices.map(x => x.value).reduce((sum, invoice) => sum + invoice, 0);
        })
    }

    update(form: NgForm): void {
        this.formSubmitted = true;
        if (form.valid) {
            let updatedModel = this.adapter.adapt(this.item);
            this.landlordService.update(updatedModel).subscribe(data => {
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            })
        }
    }

    viewPage(id: number) {
        this.router.navigate(['/flats/'+ id]);
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/landlords/alllandlords']);
            }
        })

    }

}