<ng-template #filePreviewModal1 let-modal>
    <div class="modal-body">
      <iframe [src]="fileUrl" width="100%" height="600px"></iframe>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">{{'Close' | translate}}</button>
    </div>
</ng-template>

<section  id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'FlatManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'FlatManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-header pb-2">
                        <div class="row">
                            <div class="col-md-8">
                                <h4 class="card-title" id="horz-layout-colored-controls">{{'Flat' | translate}}: {{item.title}} (#{{item.internalId}})</h4>
                                <p class="secondary">{{'CreateTime' | translate}}: {{ item.createTime | date:'yyyy-MM-dd HH:mm' }} | {{'LastUpdate' | translate}}: {{item.updateTime | date:'yyyy-MM-dd HH:mm'}}</p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <div *ngIf="viewType === 'admin'">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs justify-content-left"> 
                                
                                <li [ngbNavItem]="1">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                      <i class="ft-home mr-1"></i>
                                      <span class="d-none d-sm-block">{{'BaseInfo' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                     
                                          <div class="col-xl-3 col-lg-6 col-12">
                                              <div class="card card-inverse gradient-pomegranate">
                                                  <div class="card-content">
                                                      <div class="card-body">
                                                          <div class="media">
                                                              <div class="media-body text-left danger">
                                                                <a (click)="viewPageLandlord(item?.landlord?.id)">
                                                                    <h3 class="card-text">{{item?.landlord?.balance}} pln</h3>
                                                                    <span style="font-weight: 600;">{{item?.landlord?.firstName}} {{item?.landlord?.lastName}}</span>
                                                                </a>
                                                                <br/>
                                                                <a [href]="'mailto:' + item?.landlord?.email">
                                                                    <span style="font-size: 0.8rem">{{item?.landlord?.email}}</span>
                                                                </a>,
                                                                <br/> 
                                                                <span style="font-size: 0.8rem">{{item?.landlord?.phoneNumber}}</span>
                                                              </div>
                                                              <div class="media-right align-self-center">
                                                                    <i class="ft-home font-large-2 float-right"></i>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>

                                          <div class="col-xl-3 col-lg-6 col-12">
                                              <div *ngIf="!rcontract || rcontract === ''; else nonTenantBadge">
                                                  <div class="card card-inverse gradient-mint">
                                                      <div class="card-content">
                                                          <div class="card-body">
                                                              <div class="media">
                                                                  <div class="media-body text-left">
                                                                      <h3 class="card-text secondary">{{'Unknown' | translate}}</h3>
                                                                  </div>
                                                                  <div class="media-right align-self-center">
                                                                      <i class="ft-user font-large-2 float-right"></i>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>   
                                              </div>                      
                                              <ng-template #nonTenantBadge>
                                                  <div class="card card-inverse gradient-mint">
                                                      <div class="card-content">
                                                          <div class="card-body">
                                                              <div class="media">
                                                                  <div class="media-body text-left">
                                                                    <a (click)="viewPageTenant(rcontract.tenant.id)">
                                                                        <h3 class="card-text">{{item.balance}}</h3>
                                                                        <span style="font-weight: 600;">{{rcontract.tenant.firstName}} {{rcontract.tenant.lastName}}</span>
                                                                    </a>
                                                                    <br/>
                                                                    <a [href]="'mailto:' + rcontract.tenant.email">
                                                                        <span style="font-size: 0.8rem">{{rcontract.tenant.email}}</span>
                                                                    </a>, 
                                                                    <br/> 
                                                                        <span style="font-size: 0.8rem">{{rcontract.tenant.phoneNumber}}</span>  
                                                                  </div>
                                                                  <div class="media-right align-self-center">
                                                                      <i class="ft-user font-large-2 float-right"></i>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>              
                                              </ng-template> 
                                          </div>
                                          <div class="col-xl-3 col-lg-6 col-12">
                                              <div *ngIf="!rcontract || rcontract === ''; else nonContractBadge">
                                                  <div class="card card-inverse bg-secondary bg-lighten-3">
                                                      <div class="card-content">
                                                          <div class="card-body">
                                                              <div class="media">
                                                                  <div class="media-body text-left">
                                                                      <h3 class="card-text secondary">{{'Unknown' | translate}}</h3>
                                                                  </div>
                                                                  <div class="media-right align-self-center">
                                                                      <i class="ft-file font-large-2 float-right"></i>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>                      
                                              <ng-template #nonContractBadge>
                                                  <div class="card card-inverse gradient-purple-bliss">
                                                      <div class="card-content">
                                                          <div class="card-body">
                                                              <div class="media">
                                                                  <div class="media-body text-left">
                                                                      <h3 class="card-text">{{rcontract.status}}</h3>
                                                                      <span style="font-weight: 600;">{{rcontract.startDate | date:'yyyy-MM-dd'}} - {{rcontract.endDate | date:'yyyy-MM-dd'}}</span>
                                                                      <br/><span style="font-size: 0.8rem;">{{'MonthlyPayment' | translate}}: {{monthlyPayment}} pln</span>
                                                                  </div>
                                                                  <div class="media-right align-self-center">
                                                                      <i class="ft-file font-large-2 float-right"></i>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>                   
                                              </ng-template>
                                          </div>
                                      </div>
                                     
                                      <h4 class="card-title" style="margin-top: 50px;">{{'CostsAareement' | translate}}</h4>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="actualExpenses" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                      <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                          {{ 'ExpensesStatus.' + row.status | translate }}
                                                      </span>
                                                  </div>                      
                                                  <ng-template #nonActiveBadge>
                                                      <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                        {{ 'ExpensesStatus.' + row.status | translate }}
                                                      </span>                       
                                                  </ng-template>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{ 'ExpensesType.' + row.type | translate }}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'AccountNumber' | translate}}" prop="accountNumber"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm' }}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                              <a class="danger p-0" data-original-title="" title="download" 
                                                    *ngIf="row.fileNames && row.fileNames.length > 0"          
                                                    (click)="downloadArchive('costs-of-agreements', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                              </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable> 
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="9">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-edit mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Edit' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <form class="form" #form="ngForm" (ngSubmit)="update(form)">
                                          <div class="form-body">
                                              <div class="row">                                  
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-home mr-2"></i>{{'BaseInfo' | translate}}</h4>
                                                      <div class="form-group">
                                                          <label>{{'InternalId' | translate}} *</label>
                                                          <input [(ngModel)]="item.internalId" placeholder="{{'EnterThe' | translate}}{{'internalId' | translate}}" 
                                                              name="internalId" class="form-control" 
                                                              id="internalId" #internalId="ngModel" 
                                                              type="number" required>
                                                          <div [hidden]="internalId.valid || internalId.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input [(ngModel)]="item.title" placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                              name="title" class="form-control" 
                                                              id="position" #title="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="title.valid || title.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'YearBuilt' | translate}} *</label>
                                                          <input [(ngModel)]="item.yearBuilt" placeholder="{{'EnterThe' | translate}}{{'YearBuilt' | translate}}" 
                                                              name="yearBuilt" class="form-control" 
                                                              id="position" #yearBuilt="ngModel" 
                                                              type="number" required>
                                                          <div [hidden]="yearBuilt.valid || yearBuilt.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="item.description" id="bdescription" rows="8" 
                                                              class="form-control" name="description"
                                                              #description="ngModel" maxlength="200"></textarea>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">                                       
                                                      <h4 class="mb-3"><i class="ft-map mr-2"></i>{{'Location' | translate}}</h4>
                                                      <div class="form-group">
                                                          <label>{{'City' | translate}} *</label>
                                                          <input [(ngModel)]="item.city" placeholder="{{'EnterThe' | translate}}{{'City' | translate}}" 
                                                              name="city" class="form-control" 
                                                              id="position" #city="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="city.valid || city.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Street' | translate}} *</label>
                                                          <input [(ngModel)]="item.street" placeholder="{{'EnterThe' | translate}}{{'Street' | translate}}" 
                                                              name="street" class="form-control" 
                                                              id="street" #street="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="street.valid || street.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Building' | translate}} *</label>
                                                          <input [(ngModel)]="item.building" placeholder="{{'EnterThe' | translate}}{{'Building' | translate}}" 
                                                              name="building" class="form-control" 
                                                              id="building" #building="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="building.valid || building.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Apartment' | translate}} *</label>
                                                          <input [(ngModel)]="item.apartment" placeholder="{{'EnterThe' | translate}}{{'Apartment' | translate}}" 
                                                              name="apartment" class="form-control" 
                                                              id="apartment" #apartment="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="apartment.valid || apartment.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Postcode' | translate}} *</label>
                                                          <input [(ngModel)]="item.postcode" placeholder="{{'EnterThe' | translate}}{{'Postcode' | translate}}" 
                                                              name="postcode" class="form-control" 
                                                              id="postcode" #postcode="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="postcode.valid || postcode.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-layout mr-2"></i>{{'Size' | translate}} *</h4>
                                                      <div class="form-group">
                                                          <label>{{'Size' | translate}} *</label>
                                                          <input [(ngModel)]="item.size" placeholder="{{'EnterThe' | translate}}{{'Size' | translate}}" 
                                                              name="size" class="form-control" 
                                                              id="size" #size="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                              <div [hidden]="size.valid || size.untouched" class="text-danger">
                                                                  {{'FieldRequired' | translate}}
                                                              </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Floor' | translate}} *</label>
                                                          <input [(ngModel)]="item.floor" placeholder="{{'EnterThe' | translate}}{{'Floor' | translate}}" 
                                                              name="floor" class="form-control" 
                                                              id="floor" #floor="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                          <div [hidden]="floor.valid || floor.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'CountRooms' | translate}} *</label>
                                                          <input [(ngModel)]="item.countRooms" placeholder="{{'EnterThe' | translate}}{{'CountRooms' | translate}}" 
                                                              name="countRooms" class="form-control" 
                                                              id="countRooms" #countRooms="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                          <div [hidden]="countRooms.valid || countRooms.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'CountParkingSpots' | translate}} *</label>
                                                          <input [(ngModel)]="item.countParkingSpots" placeholder="{{'EnterThe' | translate}}{{'CountParkingSpots' | translate}}" 
                                                              name="countParkingSpots" class="form-control" 
                                                              id="countParkingSpots" #countParkingSpots="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                          <div [hidden]="countParkingSpots.valid || countParkingSpots.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>                                        
                                                  </div>
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-dollar-sign mr-2"></i>{{'Costs' | translate}}</h4>
                                                      <div class="form-group">
                                                          <label>{{'Price' | translate}} *</label>
                                                          <input [(ngModel)]="item.price" placeholder="{{'EnterThe' | translate}}{{'price' | translate}}" 
                                                              name="price" class="form-control" 
                                                              id="position" #price="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                              <div [hidden]="price.valid || price.untouched" class="text-danger">
                                                                  {{'FieldRequired' | translate}}
                                                              </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Deposit' | translate}} *</label>
                                                          <input [(ngModel)]="item.deposit" placeholder="{{'EnterThe' | translate}}{{'Deposit' | translate}}" 
                                                              name="deposit" class="form-control" 
                                                              id="deposit" #deposit="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                              <div [hidden]="deposit.valid || deposit.untouched" class="text-danger">
                                                                  {{'FieldRequired' | translate}}
                                                              </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AdminFees' | translate}} *</label>
                                                          <input [(ngModel)]="item.adminFees" placeholder="{{'EnterThe' | translate}}{{'AdminFees' | translate}}" 
                                                              name="adminFees" class="form-control" 
                                                              id="adminFees" #adminFees="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                              <div [hidden]="adminFees.valid || adminFees.untouched" class="text-danger">
                                                                  {{'FieldRequired' | translate}}
                                                              </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AccountNumber' | translate}} *</label>
                                                          <input [(ngModel)]="item.accountNumber" placeholder="{{'EnterThe' | translate}}{{'AccountNumber' | translate}}" 
                                                              name="accountNumber" class="form-control" 
                                                              id="accountNumber" #accountNumber="ngModel" 
                                                              mask="00 0000 0000 0000 0000 0000 0000"
                                                              required >
                                                          <div [hidden]="accountNumber.valid || accountNumber.untouched" class="text-danger">
                                                            {{'FeildValidationAccount' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AccountNumber2' | translate}} *</label>
                                                          <input [(ngModel)]="item.accountNumber2" placeholder="{{'EnterThe' | translate}}{{'AccountNumber2' | translate}}" 
                                                              name="accountNumber2" class="form-control" 
                                                              id="accountNumber2" #accountNumber2="ngModel" 
                                                              mask="00 0000 0000 0000 0000 0000 0000"
                                                              required>
                                                          <div [hidden]="accountNumber2.valid || accountNumber2.untouched" class="text-danger">
                                                            {{'FeildValidationAccount' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AccountNumber3' | translate}} *</label>
                                                          <input [(ngModel)]="item.accountNumber3" placeholder="{{'EnterThe' | translate}}{{'AccountNumber3' | translate}}" 
                                                              name="accountNumber3" class="form-control" 
                                                              id="accountNumber3" #accountNumber3="ngModel" 
                                                              mask="00 0000 0000 0000 0000 0000 0000"
                                                              required>
                                                          <div [hidden]="accountNumber3.valid || accountNumber3.untouched" class="text-danger">
                                                            {{'FeildValidationAccount' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Balance' | translate}} *</label>
                                                          <input type="number"  [(ngModel)]="item.balance"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Balance' | translate}}" 
                                                              #balance="ngModel"
                                                              name="itemBalance" class="form-control"  required>
                                                            <div [hidden]="balance.valid || balance.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                      </div>               
                                                  </div>
                                                  
                                              </div>
                                              <div class="row">                                  
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-settings mr-2"></i>{{'Features' | translate}}</h4>
                                                      <div class="form-group">
                                                          <div class="custom-control custom-switch custom-control-inline">
                                                              <input type="checkbox" [(ngModel)]="item.hasBalcony" checked="item.hasBalcony"
                                                                  class="custom-control-input" name="hasBalcony" id="hasBalcony">
                                                              <label class="custom-control-label" for="hasBalcony">
                                                                  {{'HasBalcony' | translate}}
                                                              </label>
                                                          </div>                                   
                                                      </div>
                                                      <div class="form-group">
                                                          <div class="custom-control custom-switch custom-control-inline">
                                                              <input type="checkbox" [(ngModel)]="item.hasTerrace" checked="item.hasTerrace"
                                                                  class="custom-control-input" name="hasTerrace" id="hasTerrace">
                                                              <label class="custom-control-label" for="hasTerrace">
                                                                  {{'HasTerrace' | translate}}
                                                              </label>
                                                          </div>                                   
                                                      </div>
                                                      <div class="form-group">
                                                          <div class="custom-control custom-switch custom-control-inline">
                                                              <input type="checkbox" [(ngModel)]="item.petAllowed" checked="item.petAllowed"
                                                                  class="custom-control-input" name="petAllowed" id="petAllowed">
                                                              <label class="custom-control-label" for="petAllowed">
                                                                  {{'PetAllowed' | translate}}
                                                              </label>
                                                          </div>                                   
                                                      </div>                                             
                                                  </div>
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-user mr-2"></i>{{'Landlord' | translate}} *</h4>
                                                      <div class="form-group">
                                                          <label>{{'Landlord' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="item.landlordId" 
                                                                  name="landlordId" #landlordId="ngModel" required>
                                                              <option *ngFor="let landlord of landlords" value="{{landlord.id}}" 
                                                                  [selected]="landlord.id === currentLandlordId"> 
                                                                  ID#{{landlord.id}} {{landlord.firstName}} {{landlord.lastName}}
                                                              </option>
                                                          </select>
                                                          <div [hidden]="landlordId.valid || landlordId.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="row">   
                                                  <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                                      <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"> 
                                                          {{'Save' | translate}}
                                                      </button><!-- [disabled]="internalId.invalid || title.invalid || yearBuilt.invalid || 
                                                      city.invalid || street.invalid || building.invalid || apartment.invalid || postcode.invalid || 
                                                      price.invalid || deposit.invalid || adminFees.invalid || 
                                                      accountNumber.invalid || accountNumber2.invalid || accountNumber3.invalid ||
                                                      size.invalid || floor.invalid || countRooms.invalid || countParkingSpots.invalid ||
                                                      landlordId.invalid"  -->
                                                      <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                          {{'Cancel' | translate}}
                                                      </button> 
                                                  </div>
                                              </div> 
                                             
                                          </div>
                                      </form>
                                  </ng-template>
                                </li>
                
                                <li [ngbNavItem]="2">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-monitor mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Equipments' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">   
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentEquipment let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewEquipment' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form class="form" #form="ngForm" (ngSubmit)="addEquipment(modal, form)">
                                                    <div class="modal-body"> 
                                                        <div class="form-group">
                                                            <label>{{'Title' | translate}} *</label>
                                                            <input  [(ngModel)]="equipment.title"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                                id="equipmentCreateTitle" #equipmentCreateTitle="ngModel" 
                                                                name="equipmentTitle" class="form-control"  required>
                                                            <div *ngIf="formSubmitted && equipmentCreateTitle.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div> 
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Description' | translate}} </label>
                                                            <textarea [(ngModel)]="equipment.description" rows="5" 
                                                                class="form-control" name="equipmentDescription"
                                                                maxlength="200"></textarea>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Comment' | translate}} </label>
                                                            <textarea [(ngModel)]="equipment.comment" rows="5" 
                                                                class="form-control" name="equipmentComment"
                                                                maxlength="200"></textarea>
                                                        </div>
                                                        <div class="form-group">
                                                            <input id="files" type="file" (change)="handleFileSelection($event, equipment)" multiple />
                                                        </div>  
                                                    </div>
                                                    <div class="modal-footer">                                         
                                                        <button type="submit" [disabled]="isLoadingEquipment" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                        <button type="button" class="btn btn-secondary" (click)="onCancel(form, modal)">{{'Cancel' | translate}}</button>
                                                    </div>
                                                </form>
                                              </ng-template>
      
                                              <ng-template #contentEquipmentEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} {{'Equipment' | translate}} #{{equipmentEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form class="form" #form="ngForm" (ngSubmit)="updateEquipment(modal, form)">
                                                  <div class="modal-body">                                                  
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="equipmentEdit.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="equipmentEditTitle" #equipmentEditTitle="ngModel"  
                                                              name="equipmentTitle" class="form-control"  required>
                                                          <div [hidden]="equipmentEditTitle.valid || equipmentEditTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="equipmentEdit.description" rows="5" 
                                                              class="form-control" name="equipmentDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="equipmentEdit.comment" rows="5" 
                                                              class="form-control" name="equipmentComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group" *ngIf="equipmentEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of equipmentEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openPreview(filePreviewModal1, 'equipments', fileName, equipmentEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('equipments', fileName, equipmentEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('equipments', fileName, equipmentEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, equipmentEdit)" multiple />
                                                      </div>
                                                    
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>  
                                              </ng-template>
                                              <button type="button" (click)="open(contentEquipment)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="equipments" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Tile' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openEquipmentEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                               
                                                <a class="danger p-0" data-original-title="" title="edit" (click)="openEquipmentEditDialog(row.id)">
                                                  <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 1)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="download" 
                                                    *ngIf="row.fileNames && row.fileNames.length > 0"
                                                    (click)="downloadArchive('equipments', row.id)">
                                                    <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                      </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="3">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-file-text mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Documents' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">   
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentDocument let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewDocument' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form #form="ngForm" (ngSubmit)="addAgreement(modal, form)">
                                                  <div class="modal-body">                                                  
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="agreement.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="agreementCreateTitle" #agreementCreateTitle="ngModel"  
                                                              name="agreementTitle" class="form-control"  required>
                                                          <div *ngIf="formSubmitted && agreementCreateTitle.invalid" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>    
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} *</label>
                                                          <textarea [(ngModel)]="agreement.description" rows="5" 
                                                              class="form-control" name="eagreementDescription"
                                                              #agreementCreateDescription="ngModel" 
                                                              maxlength="200" required></textarea>
                                                          <div *ngIf="formSubmitted && agreementCreateDescription.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Type' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="agreement.type"
                                                            #type="ngModel"  
                                                            name="agreementType" required>
                                                              <option *ngFor="let type of agreementType" value="{{type}}">       
                                                                  {{ 'AgreementType.' + type | translate }}
                                                              </option>
                                                          </select>
                                                          <div *ngIf="formSubmitted && type.invalid" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}}</label>
                                                          <input [(ngModel)]="agreement.startDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}"
                                                              id="agreementCreateStartDate" #agreementCreateStartDate="ngModel"  
                                                              name="agreementStartDate" class="form-control"                                                      
                                                              type="Date">
                                                          <div *ngIf="formSubmitted && agreementCreateStartDate.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}}</label>
                                                          <input [(ngModel)]="agreement.endDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}"
                                                              id="agreementCreateEndDate" #agreementCreateEndDate="ngModel"  
                                                              name="agreementEndDate" class="form-control"                                                      
                                                              type="Date">
                                                          <div *ngIf="formSubmitted && agreementCreateEndDate.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}} 
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                        <label>{{'Files' | translate}} *</label>
                                                        <input id="files" type="file" (change)="handleFileSelection($event, agreement)" multiple />
                                                        <div *ngIf="formSubmitted && !isFileContractSelected" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>  
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                      <!--[disabled]="agreementCreateTitle.invalid || agreementCreateStartDate.invalid || agreementCreateEndDate.invalid
                                                                   || agreementCreateDescription.invalid || !isFileContractSelected || isLoadingAgreement"-->
                                                    <button type="button" class="btn btn-secondary" (click)="onCancel(form, modal)">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>    
                                              </ng-template>
      
                                              <ng-template #contentAgreementEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} {{'Agreement' | translate}} #{{agreementEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form class="form" #form="ngForm" (ngSubmit)="updateAgreement(modal, form)">
                                                  <div class="modal-body">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="agreementEdit.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                              id="agreementUpdateTitle" #agreementUpdateTitle="ngModel" 
                                                              name="agreementTitle" class="form-control"  required>
                                                          <div *ngIf="formSubmitted && agreementUpdateTitle.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>    
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} *</label>
                                                          <textarea [(ngModel)]="agreementEdit.description" rows="5" 
                                                              class="form-control" name="eagreementDescription"
                                                              #agreementUpdateDescription="ngModel"
                                                              maxlength="200" required></textarea>
                                                          <div *ngIf="formSubmitted && agreementUpdateDescription.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Type' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="agreementEdit.type" 
                                                              name="agreementType" required>
                                                              <option *ngFor="let type of agreementType" value="{{type}}">
                                                                {{ 'AgreementType.' + type | translate }}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}}</label>
                                                          <input [(ngModel)]="agreementEdit.startDate"
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}" 
                                                              id="agreementUpdateStartDate" #agreementUpdateStartDate="ngModel" 
                                                              name="agreementStartDate" class="form-control"                                                      
                                                              type="Date">
                                                          <div *ngIf="formSubmitted && agreementUpdateStartDate.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}}</label>
                                                          <input [(ngModel)]="agreementEdit.endDate"
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}" 
                                                              id="agreementUpdateEndDate" #agreementUpdateEndDate="ngModel"
                                                              name="agreementEndDate" class="form-control"                                                      
                                                              type="Date">
                                                          <div *ngIf="formSubmitted && agreementUpdateEndDate.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group" *ngIf="agreementEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of agreementEdit.fileNames">
                                                                <span class="fileName cursor-pointer" 
                                                                      (click)="openPreview(filePreviewModal1, 'agreements', fileName, agreementEdit.id)">
                                                                  {{ fileName }}
                                                                </span>
                                                                <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('agreements', fileName, agreementEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                </a>
                                                                <a class="danger p-0" data-original-title="" title="delete" 
                                                                    (click)="removeFile('agreements', fileName, agreementEdit.id)">
                                                                    <i class="ft-trash cursor-pointer mr-2"></i>
                                                                </a>
                                                              </li>
                                                          </ul>        
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, agreementEdit)" multiple />
                                                      </div>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                      <!--[disabled]="agreementUpdateTitle.invalid || agreementUpdateStartDate.invalid || agreementUpdateEndDate.invalid 
                                                      || agreementUpdateDescription.invalid"-->
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>
                                              </ng-template>
                                              
                                              <button type="button" (click)="open(contentDocument)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="agreements" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Tile' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openAgreementEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.startDate ? (row.startDate | date:'yyyy-MM-dd') : '-'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.endDate ? (row.endDate | date:'yyyy-MM-dd') : '-'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{ 'AgreementType.' + row.type | translate }}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                               
                                                <a class="danger p-0" data-original-title="" title="edit" (click)="openAgreementEditDialog(row.id)">
                                                  <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 2)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="download" 
                                                    *ngIf="row.fileNames && row.fileNames.length > 0"
                                                    (click)="downloadArchive('agreements', row.id)">
                                                <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="4">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-clipboard mr-1"></i>
                                    <span class="d-none d-sm-block"> {{'Inspections' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                          <ng-template #contentInspection let-c="close" let-d="dismiss" let-modal>
                                              <div class="modal-header">
                                                <h4 class="modal-title">{{'AddNewInspections' | translate}}</h4>
                                                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                              </div>
                                              <div class="modal-body">
                                                <form class="form">
                                                  <div class="form-group"> 
                                                      <input id="files" type="file" (change)="handleFileSelection($event, inspection)" multiple />
                                                  </div>
                                                </form>
                                              </div>
                                              <div class="modal-footer">                                         
                                                <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" [disabled]="isLoadingInspection"
                                                        (click)="addInspection(modal, form)">{{'Save' | translate}}</button>
                                                <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                              </div>
                                          </ng-template>
                                          <button type="button" (click)="open(contentInspection)"
                                              class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                              {{'Add' | translate}}
                                          </button>
                                      </div>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="inspections" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                               
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 3)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('inspections', row.id)">
                                                    <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column>          
                                      </ngx-datatable>    
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="5">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-droplet mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Meters' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                              <ng-template #content let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewMeter' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <form class="form"  #form="ngForm" (ngSubmit)="addMeter(modal, form)">
                                                    <div class="modal-body">
                                                        <div class="form-group">
                                                            <label>{{'Type' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meter.type" 
                                                                name="meterType" #type="ngModel" required>
                                                                <option *ngFor="let type of meterTypes" value="{{type}}">
                                                                    {{ 'MeterType.' + type | translate }}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="formSubmitted && type.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Category' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meter.category" 
                                                                name="meterCategory" #category="ngModel" required>
                                                                <option *ngFor="let category of meterCategories" value="{{category}}">
                                                                    {{ 'MeterCategory.' + category | translate }}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="formSubmitted && category.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Value' | translate}} *</label>
                                                            <input  [(ngModel)]="meter.value"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                                                id="meterCreateValue" #meterCreateValue="ngModel" 
                                                                name="meterValue" class="form-control" 
                                                                type="number"
                                                                step="any" required>
                                                            <div *ngIf="formSubmitted && meterCreateValue.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div> 
                                                        </div>
                                                        <div class="form-group">
                                                            <input id="files" type="file" (change)="handleFileSelection($event, meter)" multiple />
                                                        </div>
                                                    </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                    <!--[disabled]="meterCreateValue.invalid || isLoadingMeter"-->
                                                    <button type="button" class="btn btn-secondary" (click)="onCancel(form, modal)">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>
                                              </ng-template>
                                              <button type="button" (click)="open(content)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
      
                                              <ng-template #contentMeterEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} {{'Meter' | translate}} #{{meterEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form class="form" #form="ngForm" (ngSubmit)="updateMeter(modal, form)">
                                                    <div class="modal-body">
                                                        <div class="form-group">
                                                            <label>{{'Type' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meterEdit.type" 
                                                                name="meterType" #type="ngModel" required>
                                                                <option *ngFor="let type of meterTypes" value="{{type}}">
                                                                    {{type}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="formSubmitted && type.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Category' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meterEdit.category" 
                                                                name="meterCategory" #category="ngModel" required>
                                                                <option *ngFor="let category of meterCategories" value="{{category}}">
                                                                    {{category}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="formSubmitted && category.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Value' | translate}} *</label>
                                                            <input  [(ngModel)]="meterEdit.value"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                                                id="meterUpdateValue" #meterUpdateValue="ngModel"
                                                                name="meterValue" class="form-control" 
                                                                type="number"
                                                                step="any" required>
                                                            <div *ngIf="formSubmitted && meterUpdateValue.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div> 
                                                        </div>
                                                        <div class="form-group" *ngIf="meterEdit.fileNames?.length > 0">
                                                            <label>{{'UploadedFiles' | translate}}:</label>
                                                            <ul>
                                                                <li *ngFor="let fileName of meterEdit.fileNames">
                                                                    <span class="fileName cursor-pointer" 
                                                                            (click)="openPreview(filePreviewModal1, 'meters', fileName, meterEdit.id)"> <!--openModal(filePreviewModal1, 'documents', fileName, item.id)-->
                                                                        {{ fileName }}
                                                                    </span>
                                                                    <a class="danger p-0" data-original-title="" title="download" 
                                                                        (click)="downloadFile('meters', fileName, meterEdit.id)">
                                                                        <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                    </a>
                                                                    <a class="danger p-0" data-original-title="" title="delete" 
                                                                        (click)="removeFile('meters', fileName, meterEdit.id)">
                                                                        <i class="ft-trash cursor-pointer mr-2"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="form-group">
                                                            <input id="files" type="file" (change)="handleFileSelection($event, meterEdit)" multiple />
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="modal-footer">                                         
                                                        <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                        <!--[disabled]="meterUpdateValue.invalid" -->
                                                        <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                    </div>
                                                </form>
                                              </ng-template>
                                          </div>
                                      </div>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="meters" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openMeterEditDialog(row.id)">
                                                    {{ 'MeterType.' + row.type | translate }}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Category' | translate}}">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{ 'MeterCategory.' + row.category | translate }}
                                            </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                               
                                                <a class="danger p-0" data-original-title="" title="edit" (click)="openMeterEditDialog(row.id)">
                                                  <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 4)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="download" 
                                                    *ngIf="row.fileNames && row.fileNames.length > 0"    
                                                    (click)="downloadArchive('meters', row.id)">
                                                <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>    
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="6">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-file mr-1"></i>
                                    <span class="d-none d-sm-block"> {{'Contracts' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                              <ng-template #contentContract let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewContract' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form #form="ngForm" class="form" (ngSubmit)="addContract(modal, form)">
                                                  <div class="modal-body">
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}} *</label>
                                                          <input [(ngModel)]="contract.startDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}" 
                                                              id="contractCreateStartDate" #contractCreateStartDate="ngModel"
                                                              name="contractStartDate" class="form-control"                                                      
                                                              type="Date" required>                                
                                                          <div  *ngIf="formSubmitted && contractCreateStartDate.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}} *</label>
                                                          <input [(ngModel)]="contract.endDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}"
                                                              id="contractCreateEndDate" #contractCreateEndDate="ngModel" 
                                                              name="contractEndDate" class="form-control"                                                      
                                                              type="Date" required>
                                                          <div *ngIf="formSubmitted && contractCreateEndDate.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select [(ngModel)]="contract.status" 
                                                                #contractCreateStatus="ngModel"
                                                                name="contractStatus" class="form-control"  required>
                                                              <option *ngFor="let status of contractStatus" value="{{status}}">
                                                                  {{status}}
                                                              </option>
                                                          </select>
                                                        <div *ngIf="formSubmitted && contractCreateStatus.invalid" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'RentPrice' | translate}} *</label>
                                                          <input  [(ngModel)]="contract.rentPrice"   
                                                              placeholder="{{'EnterThe' | translate}}{{'RentPrice' | translate}}" 
                                                              id="contractCreateRent" #contractCreateRent="ngModel"
                                                              name="contractRent" step="any"
                                                              class="form-control" 
                                                              type="number" required>
                                                          <div *ngIf="formSubmitted && contractCreateRent.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'HgmtFee' | translate}} *</label>
                                                          <input  [(ngModel)]="contract.hgmtFee"   
                                                              placeholder="{{'EnterThe' | translate}}{{'HgmtFee' | translate}}" 
                                                              id="contractCreateHgmtFee" #contractCreateHgmtFee="ngModel"
                                                              name="contractHgmtFee" step="any"
                                                              class="form-control" 
                                                              type="number" required>
                                                          <div *ngIf="formSubmitted && contractCreateHgmtFee.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Tenant' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="contract.tenantId" 
                                                                #contractTenantId="ngModel"
                                                                name="contractTenantId" required>
                                                              <option *ngFor="let tenant of tenants" value="{{tenant.id}}"> 
                                                                  ID#{{tenant.id}} {{tenant.firstName}} {{tenant.lastName}}
                                                              </option>
                                                          </select>
                                                        <div *ngIf="formSubmitted && contractTenantId.invalid" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Employee' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="contract.employeeId" 
                                                                #contractEmployeeId="ngModel"
                                                                name="contractEmployeeId" required>
                                                              <option *ngFor="let employee of employees" value="{{employee.id}}"> 
                                                                  ID#{{employee.id}} {{employee.firstName}} {{employee.lastName}}
                                                              </option>
                                                          </select>
                                                        <div *ngIf="formSubmitted && contractEmployeeId.invalid" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>
                                                      <div class="form-group">
                                                        <label>{{'Files' | translate}}: *</label>
                                                        <input id="files" type="file" (change)="handleFileSelection($event, contract)" multiple />
                                                        <div *ngIf="!isFileContractSelected" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>
                                                    
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                      <!--[disabled]="contractCreateStartDate.invalid || contractCreateEndDate.invalid || contractCreateStatus.invalid ||
                                                                  contractCreateRent.invalid || contractCreateHgmtFee.invalid || 
                                                                  contractTenantId.invalid || contractEmployeeId.invalid || 
                                                                  !isFileContractSelected ||isLoadingContract"-->
                                                    <button type="button" class="btn btn-secondary" (click)="onCancel(form, modal)">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>
                                              </ng-template>
                                              <button type="button" (click)="open(contentContract)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
      
                                              <ng-template #contentContractEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} {{'Contract' | translate}} #{{contractEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form #form="ngForm" class="form" (ngSubmit)="updateContract(modal, form)">
                                                  <div class="modal-body">
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}} *</label>
                                                          <input [(ngModel)]="contractEdit.startDate"
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}" 
                                                              id="contractUpdateStartDate" #contractUpdateStartDate="ngModel"
                                                              name="contractStartDate" class="form-control"                                                      
                                                              type="Date" required>
                                                          <div *ngIf="formSubmitted && contractUpdateStartDate.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}} *</label>
                                                          <input [(ngModel)]="contractEdit.endDate"
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}" 
                                                              id="contractUpdateEndDate" #contractUpdateEndDate="ngModel"
                                                              name="contractEndDate" class="form-control"                                                      
                                                              type="Date" required>
                                                          <div *ngIf="formSubmitted && contractUpdateEndDate.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select [(ngModel)]="contractEdit.status"
                                                            #contractUpdateStatus="ngModel" 
                                                            name="contractStatus" class="form-control"  required>
                                                              <option *ngFor="let status of contractStatus" value="{{status}}">
                                                                  {{status}}
                                                              </option>
                                                          </select>
                                                        <div *ngIf="formSubmitted && contractUpdateStatus.invalid" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'RentPrice' | translate}} *</label>
                                                          <input  [(ngModel)]="contractEdit.rentPrice"   
                                                              placeholder="{{'EnterThe' | translate}}{{'RentPrice' | translate}}" 
                                                              id="contractUpdateRent" #contractUpdateRent="ngModel"
                                                              name="contractRent" step="any"
                                                              class="form-control" 
                                                              type="number" required>
                                                          <div *ngIf="formSubmitted && contractUpdateRent.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'HgmtFee' | translate}} *</label>
                                                          <input  [(ngModel)]="contractEdit.hgmtFee"   
                                                              placeholder="{{'EnterThe' | translate}}{{'HgmtFee' | translate}}" 
                                                              id="contractUpdateHgmtFee" #contractUpdateHgmtFee="ngModel"
                                                              name="contractHgmtFee" step="any"
                                                              class="form-control" 
                                                              type="number" required>
                                                          <div *ngIf="formSubmitted && contractUpdateHgmtFee.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Tenant' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="contractTenantId" 
                                                                  name="contractTenantId" required disabled>
                                                              <option *ngFor="let tenant of tenants" value="{{tenant.id}}"> 
                                                                  ID#{{tenant.id}} {{tenant.firstName}} {{tenant.lastName}} 
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Employee' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="contractEdit.employee.id"
                                                                 #contractUpdateEmployeeId="ngModel" name="contractUpdateEmployeeId" 
                                                                 required> 
                                                              <option *ngFor="let employee of employees" value="{{employee.id}}"> 
                                                                  ID#{{employee.id}} {{employee.firstName}} {{employee.lastName}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group" *ngIf="contractEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of contractEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openPreview(filePreviewModal1, 'rental-contracts', fileName, contractEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('rental-contracts', fileName, contractEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('rental-contracts', fileName, contractEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                        <label>{{'Files' | translate}}: *</label>
                                                        <input id="files" type="file" class="form-control"  (change)="handleFileSelection($event, contractEdit)" multiple />
                                                        <div *ngIf="contractEdit.fileNames?.length === 0 || !contractEdit.fileNames" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>
                                                    
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                    <!--[disabled]="contractUpdateStartDate.invalid || contractUpdateEndDate.invalid || 
                                                                  contractUpdateRent.invalid || contractUpdateHgmtFee.invalid"-->
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>
                                              </ng-template>
                                          </div>
                                      </div>
                                          <ngx-datatable class="bootstrap core-bootstrap" 
                                              [rows]="contracts" 
                                              [columnMode]="'force'" 
                                              [headerHeight]="50" 
                                              [footerHeight]="50"
                                              [rowHeight]="'auto'"
                                              [limit]="15">
                                              <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                          <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                              {{ 'RentalContractStatus.' + row.status | translate }}
                                                          </span>
                                                      </div>                      
                                                      <ng-template #nonActiveBadge>
                                                          <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                              {{ 'RentalContractStatus.' + row.status | translate }}
                                                          </span>                       
                                                      </ng-template>
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <a class="primary p-0" data-original-title="" (click)="openContractEditDialog(row.id)">
                                                          {{row.startDate | date:'yyyy-MM-dd'}}
                                                      </a>
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      {{row.endDate | date:'yyyy-MM-dd'}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'RentPrice' | translate}}" prop="rentPrice"  ></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'HGMTFee' | translate}}" prop="hgmtFee"></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'Actions' | translate}}">
                                                  <ng-template let-row="row"  ngx-datatable-cell-template>
                                                   
                                                    <a class="danger p-0" data-original-title="" title="edit" (click)="openContractEditDialog(row.id)">
                                                      <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                    <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 5)">
                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                    </a>
                                                    <a class="danger p-0" data-original-title="" title="download" 
                                                        *ngIf="row.fileNames && row.fileNames.length > 0"    
                                                        (click)="downloadArchive('rental-contracts', row.id)">
                                                        <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                  </ng-template>
                                              </ngx-datatable-column> 
                                          </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="7">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-dollar-sign mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Invoices' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                          <div class="col-3 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                              <h2 class="card-title text-right">
                                                  {{'Balance' | translate}}: {{balance}} PLN
                                              </h2>
                                          </div>   
                                          <div class="col-9 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentInvoice let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewInvoice' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form class="form" #form="ngForm" (ngSubmit)="addInvoice(modal, form)">
                                                  <div class="modal-body">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="invoice.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="invoiceCreateTitle" #invoiceCreateTitle="ngModel" 
                                                              name="invoiceTitle" class="form-control"  required>
                                                          <div *ngIf="formSubmitted && invoiceCreateTitle.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Value' | translate}} *</label>
                                                          <input type="number"  [(ngModel)]="invoice.value"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}"
                                                              id="invoiceCreateValue" #invoiceCreateValue="ngModel"  
                                                              name="invoiceValue" step="any" 
                                                              class="form-control"  required>
                                                          <div *ngIf="formSubmitted && invoiceCreateValue.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDateInvoice' | translate}} *</label>
                                                          <input [(ngModel)]="invoice.endTime" 
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}"
                                                              id="invoiceCreateEndTime" #invoiceCreateEndTime="ngModel"  
                                                              name="invoiceEndTime" class="form-control"                                                      
                                                              type="Date" required>  
                                                          <div *ngIf="formSubmitted && invoiceCreateEndTime.invalid" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="invoice.description" rows="5" 
                                                              class="form-control" name="invoiceDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="invoice.comment" rows="5" 
                                                              class="form-control" name="invoiceComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="invoice.status" 
                                                                id="invoiceCreateStatus" #invoiceCreateStatus="ngModel"      
                                                                name="invoiceStatus"required>
                                                              <option *ngFor="let status of invoiceStatus" value="{{status}}">
                                                                 {{ 'InvoiceStatus.' + status | translate }}
                                                              </option>
                                                          </select>
                                                        <div *ngIf="formSubmitted && invoiceCreateStatus.invalid" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, invoice)" multiple />
                                                      </div>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                      <!-- [disabled]="invoiceCreateTitle.invalid || invoiceCreateValue.invalid || invoiceCreateEndTime.invalid || invoiceCreateStatus.invalid || isLoadingInvoices"-->
                                                    <button type="button" class="btn btn-secondary" (click)="onCancel(form, modal)">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>
                                              </ng-template>
                                              <button type="button" (click)="open(contentInvoice)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
      
                                              <ng-template #contentInvoiceEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} {{'Invoice' | translate}} #{{invoiceEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form class="form" #form="ngForm" (ngSubmit)="updateInvoice(modal, form)">
                                                  <div class="modal-body">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="invoiceEdit.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="invoiceUpdateTitle" #invoiceUpdateTitle="ngModel"
                                                              name="invoiceTitle" class="form-control"  required>
                                                          <div [hidden]="invoiceUpdateTitle.valid || invoiceUpdateTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Value' | translate}} *</label>
                                                          <input type="number"  [(ngModel)]="invoiceEdit.value"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}"
                                                              id="invoiceUpdateValue" #invoiceUpdateValue="ngModel" 
                                                              name="invoiceValue"  step="any"
                                                              class="form-control"  required>
                                                          <div [hidden]="invoiceUpdateValue.valid || invoiceUpdateValue.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDateInvoice' | translate}} *</label>
                                                          <input [(ngModel)]="invoiceEdit.endTime"
                                                            
                                                            placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}" 
                                                            id="invoiceUpdateEndTime" #invoiceUpdateEndTime="ngModel"
                                                            name="invoiceUpdateEndTime" class="form-control"                                                      
                                                            type="Date" required>
                                                          <div [hidden]="invoiceUpdateEndTime.valid || invoiceUpdateEndTime.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="invoiceEdit.description" rows="5" 
                                                              class="form-control" name="invoiceDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="invoiceEdit.comment" rows="5" 
                                                              class="form-control" name="invoiceComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="invoiceEdit.status" 
                                                            id="invoiceUpdateStatus" #invoiceUpdateStatus="ngModel"    
                                                            name="invoiceStatus" required>
                                                                <option *ngFor="let status of invoiceStatus" value="{{status}}">
                                                                    {{ 'InvoiceStatus.' + status | translate }}
                                                                </option>
                                                          </select>
                                                          <div *ngIf="formSubmitted && invoiceUpdateStatus.invalid" class="text-danger">
                                                            {{'FieldRequired' | translate}}
                                                        </div>
                                                      </div>
                                                      <div class="form-group" *ngIf="invoiceEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of invoiceEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openPreview(filePreviewModal1, 'invoices', fileName, invoiceEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('invoices', fileName, invoiceEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('invoices', fileName, invoiceEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, invoiceEdit)" multiple />
                                                      </div>
                                                    
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                          <!--[disabled]="invoiceUpdateTitle.invalid || invoiceUpdateValue.invalid || 
                                                          invoiceUpdateEndTime.invalid || invoiceUpdateStatus.invalid"-->
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>
                                              </ng-template>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="invoices" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                      <!--{{row.status}} -->
                                                      {{ 'InvoiceStatus.' + row.status | translate }}
                                                  </span>
                                                  </div>                      
                                                  <ng-template #nonActiveBadge>
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                      <!--{{row.status}}-->
                                                      {{ 'InvoiceStatus.' + row.status | translate }}
                                                  </span>                       
                                                  </ng-template>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Title' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openInvoiceEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'EndTime' | translate}}" >
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.endTime | date:'yyyy-MM-dd'}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                               
                                                <a class="danger p-0" data-original-title="" title="edit" (click)="openInvoiceEditDialog(row.id)">
                                                  <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 6)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="download" 
                                                    *ngIf="row.fileNames && row.fileNames.length > 0"
                                                    (click)="downloadArchive('invoices', row.id)">
                                                    <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>
                                  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="8">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-briefcase mr-1"></i>
                                    <span class="d-none d-sm-block">{{'CostsAareement' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">   
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentExpenses let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewExpenses' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form #form="ngForm" class="form" (ngSubmit)="addExpenses(modal, form)">
                                                  <div class="modal-body">
                                                        <div class="form-group">
                                                            <label>{{'Type' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="expense.type" 
                                                                id="expensesCreateType" #expensesCreateType="ngModel"    
                                                                name="expensesType" required>
                                                                <option *ngFor="let type of expensesType" value="{{type}}">
                                                                    {{ 'ExpensesType.' + type | translate }}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="formSubmitted && expensesCreateType.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Status' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="expense.status" 
                                                                id="expensesCreateStatus" #expensesCreateStatus="ngModel" 
                                                                name="expensesStatus" required>
                                                                <option *ngFor="let status of expensesStatus" value="{{status}}">
                                                                    {{status}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="formSubmitted && expensesCreateStatus.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'StartDate' | translate}} *</label>
                                                            <input type="Date"  [(ngModel)]="expense.startDate"   
                                                                placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}"
                                                                id="expensesCreateStartDate" #expensesCreateStartDate="ngModel"  
                                                                name="expensesStartDate" class="form-control">
                                                            <div *ngIf="formSubmitted && expensesCreateStartDate.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Value' | translate}} *</label>
                                                            <input type="number"  [(ngModel)]="expense.value"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}"
                                                                id="expenseCreateValue" #expenseCreateValue="ngModel"  
                                                                name="expenseValue" step="any"
                                                                class="form-control"  required>
                                                            <div *ngIf="formSubmitted && expenseCreateValue.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'AccountNumber' | translate}} *</label>
                                                            <input  [(ngModel)]="expense.accountNumber"   
                                                                placeholder="{{'EnterThe' | translate}}{{'AccountNumber' | translate}}"
                                                                id="expenseCreateAccountNumber" #expenseCreateAccountNumber="ngModel"  
                                                                name="expenseAccountNumber" class="form-control"  required
                                                                mask="00 0000 0000 0000 0000 0000 0000">
                                                            <div *ngIf="formSubmitted && expenseCreateAccountNumber.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Comment' | translate}} </label>
                                                            <textarea [(ngModel)]="expense.comment" rows="5" 
                                                                class="form-control" name="expenseComment"
                                                                maxlength="200"></textarea>
                                                        </div>
                                                        <div class="form-group">
                                                            <input id="files" type="file" (change)="handleFileSelection($event, expense)" multiple />
                                                        </div>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                    <!-- [disabled]="expenseCreateValue.invalid || expenseCreateAccountNumber.invalid || 
                                                                        expensesCreateStartDate.invalid || expensesCreateType.invalid ||  
                                                                        expensesCreateStatus.invalid || isLoadingExpenses"-->
                                                    <button type="button" class="btn btn-secondary" (click)="onCancel(form, modal)">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>
                                              </ng-template> 
                                              <button type="button" (click)="open(contentExpenses)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
      
                                              <ng-template #contentExpensesEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} {{'Costs' | translate}} #{{expensesEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                <form class="form" #form="ngForm" (ngSubmit)="updateExpenses(modal, form)">
                                                  <div class="modal-body">
                                                        <div class="form-group">
                                                            <label>{{'Type' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="expensesEdit.type"  
                                                                id="expensesUpdateType" #expensesUpdateType="ngModel"
                                                                name="expensesType" required>
                                                                    <option *ngFor="let type of expensesType" value="{{type}}">
                                                                        {{ 'ExpensesType.' + type | translate }}
                                                                    </option>
                                                            </select>
                                                            <div *ngIf="formSubmitted && expensesUpdateType.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Status' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="expensesEdit.status" 
                                                                id="expensesUpdateStatus" #expensesUpdateStatus="ngModel"     
                                                                name="expensesStatus" required>
                                                                    <option *ngFor="let status of expensesStatus" value="{{status}}">
                                                                        {{status}}
                                                                    </option>
                                                            </select>
                                                            <div *ngIf="formSubmitted && expensesUpdateStatus.invalid" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'StartDate' | translate}} *</label>
                                                            <input type="Date"  [(ngModel)]="expensesEdit.startDate"
                                                                placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}"
                                                                id="expensesUpdateStartDate" #expensesUpdateStartDate="ngModel"  
                                                                name="expensesStartDate" class="form-control"  required>
                                                            <div [hidden]="expensesUpdateStartDate.valid || expensesUpdateStartDate.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Value' | translate}} *</label>
                                                            <input type="number"  [(ngModel)]="expensesEdit.value"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}"
                                                                id="expenseUpdateValue" #expenseUpdateValue="ngModel" 
                                                                name="expenseValue" step="any"
                                                                class="form-control"  required>
                                                            <div [hidden]="expenseUpdateValue.valid || expenseUpdateValue.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'AccountNumber' | translate}} *</label>
                                                            <input  [(ngModel)]="expensesEdit.accountNumber"   
                                                                placeholder="{{'EnterThe' | translate}}{{'AccountNumber' | translate}}"
                                                                id="expenseUpdateAccountNumber" #expenseUpdateAccountNumber="ngModel" 
                                                                name="expenseAccountNumber" class="form-control"  required
                                                                mask="00 0000 0000 0000 0000 0000 0000">
                                                            <div [hidden]="expenseUpdateAccountNumber.valid || expenseUpdateAccountNumber.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div>
                                                        </div>
                                                        <div class="custom-control custom-switch custom-control-inline margin-bottom-20">
                                                            <input type="checkbox" [(ngModel)]="expensesEdit.isSend"
                                                                class="custom-control-input" name="isSend" id="expIsSend">
                                                            <label class="custom-control-label" for="expIsSend">
                                                                {{'IsSendNotify' | translate}}
                                                            </label>
                                                        </div>  
                                                        <div class="form-group">
                                                            <label>{{'Comment' | translate}} </label>
                                                            <textarea [(ngModel)]="expensesEdit.comment" rows="5" 
                                                                class="form-control" name="expenseComment"
                                                                maxlength="200"></textarea>
                                                        </div>
                                                        <div class="form-group" *ngIf="expensesEdit.fileNames?.length > 0">
                                                            <label>{{'UploadedFiles' | translate}}:</label>
                                                            <ul>
                                                                <li *ngFor="let fileName of expensesEdit.fileNames">
                                                                    <span class="fileName cursor-pointer" 
                                                                            (click)="openPreview(filePreviewModal1, 'costs-of-agreements', fileName, expensesEdit.id)">
                                                                        {{ fileName }}
                                                                    </span>
                                                                    <a class="danger p-0" data-original-title="" title="download" 
                                                                        (click)="downloadFile('costs-of-agreements', fileName, expensesEdit.id)">
                                                                        <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                    </a>
                                                                    <a class="danger p-0" data-original-title="" title="delete" 
                                                                        (click)="removeFile('costs-of-agreements', fileName, expensesEdit.id)">
                                                                        <i class="ft-trash cursor-pointer mr-2"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="form-group">
                                                            <input id="files" type="file" (change)="handleFileSelection($event, expensesEdit)" multiple />
                                                        </div>
                                                    
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                                    <!--[disabled]="expenseUpdateValue.invalid || expenseUpdateAccountNumber.invalid || 
                                                          expensesUpdateStartDate.invalid || expensesUpdateStatus.invalid || expensesUpdateType.invalid"-->
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                                </form>
                                              </ng-template>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="expenses" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                      <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                      {{ 'ExpensesStatus.' + row.status | translate }}
                                                  </span>
                                              </div>                      
                                              <ng-template #nonActiveBadge>
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                      {{ 'ExpensesStatus.' + row.status | translate }}
                                                  </span>                       
                                              </ng-template>
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              <a class="primary p-0" data-original-title="" (click)="openExpensesEditDialog(row.id)">
                                                {{ 'ExpensesType.' + row.type | translate }}
                                              </a>
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'AccountNumber' | translate}}" prop="accountNumber"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Actions' | translate}}">
                                          <ng-template let-row="row"  ngx-datatable-cell-template>
                                            
                                            <a class="danger p-0" data-original-title="" title="edit" (click)="openExpensesEditDialog(row.id)">
                                              <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                            </a>
                                            <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 7)">
                                              <i class="ft-trash cursor-pointer mr-2"></i>
                                            </a>
                                            <a class="danger p-0" data-original-title="" title="download" 
                                                *ngIf="row.fileNames && row.fileNames.length > 0"
                                                (click)="downloadArchive('costs-of-agreements', row.id)">
                                              <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                            </a>
                                          </ng-template>
                                      </ngx-datatable-column> 
                                      </ngx-datatable> 
                                  </ng-template>
                           
                                </li>
                                
                                <li [ngbNavItem]="10">
                                    <a ngbNavLink class="nav-link d-flex align-items-center">
                                        <i class="ft-dollar-sign mr-1"></i>
                                        <span class="d-none d-sm-block">{{'Payments' | translate}}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <div class="row">
                                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                            
                                                <ng-template #contentTransactionEdit let-c="close" let-d="dismiss" let-modal>
                                                    <div class="modal-header">
                                                      <h4 class="modal-title">{{'Update' | translate}}</h4>
                                                      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                      </button>
                                                    </div>
                                                    <div class="modal-body">
                                                      <form class="form">
                                                        <div class="form-group">
                                                            <label>{{'Sum' | translate}} *</label>
                                                            <input  [(ngModel)]="transactionEdit.sum"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                                                id="transactionSum" #transactionSum="ngModel" 
                                                                name="transactionSum" class="form-control" 
                                                                type="number" required>
                                                            <div [hidden]="transactionSum.valid || transactionSum.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div> 
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Description' | translate}} </label>
                                                            <textarea [(ngModel)]="transactionEdit.description" rows="5" 
                                                                class="form-control" name="transactionDescription"
                                                                maxlength="200"></textarea>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Status' | translate}} *</label>
                                                            <select [(ngModel)]="transactionEdit.status" 
                                                                name="transactionStatus" class="form-control"  required>
                                                                <option *ngFor="let status of transactionStatus" value="{{status}}">
                                                                    {{status}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Flat' | translate}} *</label>                                  
                                                            <select class="form-control"  [(ngModel)]="transactionEdit.flatId" 
                                                                    name="flatId" #flatId="ngModel" required>
                                                                <option *ngFor="let flat of flats" value="{{flat.id}}" > 
                                                                    InternalID#{{flat.internalId}} {{flat.title}} [{{flat.id}}]
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="modal-footer">                                         
                                                            <button type="submit" 
                                                                  class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateTransaction(modal)">{{'Save' | translate}}</button>
                                                            <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                        </div>
                                                      </form>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <ngx-datatable class="bootstrap core-bootstrap" 
                                            [rows]="transactions" 
                                            [columnMode]="'force'" 
                                            [headerHeight]="50" 
                                            [footerHeight]="50"
                                            [rowHeight]="'auto'"
                                            [limit]="15"
                                            [sorts]="[{ prop: 'id', dir: 'desc' }]">
                                            <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Sum' | translate}}" prop="sum"></ngx-datatable-column>   
                                            <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <div *ngIf="row.status == 'Error'; else successBadge">
                                                        <span _ngcontent-ykd-c258="" class="badge bg-light-danger mb-1">
                                                            {{row.status}}
                                                        </span>
                                                    </div>                      
                                                    <ng-template #successBadge>
                                                        <div *ngIf="row.status == 'CompletedAuto'; else secondaryBadge">
                                                            <span class="badge bg-light-success mb-1">
                                                                {{row.status}}
                                                            </span>
                                                        </div>                       
                                                    </ng-template>
                                                    <ng-template #secondaryBadge>
                                                        <span class="badge bg-light-secondary mb-1">
                                                            {{row.status}}
                                                        </span>
                                                    </ng-template>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'ExportFile' | translate}}" prop="exportFilesId">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <a class="primary p-0" data-original-title="" title="edit" (click)="viewFilePage(row.exportFilesId)">
                                                        {{row.exportFilesId}}
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Description' | translate}}" prop="description"></ngx-datatable-column>  
                                            <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'PaymentTime' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.paymentTime | date:'yyyy-MM-dd HH:mm'}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            
                                            <ngx-datatable-column name="{{'Actions' | translate}}">
                                                <ng-template let-row="row"  ngx-datatable-cell-template>                     
                                                    <a class="danger p-0" data-original-title="" title="edit" (click)="openTransactionEditDialog(row.id)">
                                                        <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                    <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 8)">
                                                        <i class="ft-trash cursor-pointer mr-2"></i>
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column> 
                                        </ngx-datatable> 
                                    </ng-template>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div><!----./admin-->

                        <div *ngIf="viewType === 'landlord'">
                            <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-left"> 
                                <li [ngbNavItem]="1">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-monitor mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Equipments' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">   
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentEquipment let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewEquipment' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form #form="ngForm" class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="equipment.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                              id="equipmentCreateTitle" #equipmentCreateTitle="ngModel" 
                                                              name="equipmentTitle" class="form-control"  required>
                                                          <div [hidden]="equipmentCreateTitle.valid || equipmentCreateTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>  
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="equipment.description" rows="5" 
                                                              class="form-control" name="equipmentDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="equipment.comment" rows="5" 
                                                              class="form-control" name="equipmentComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, equipment)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" 
                                                          [disabled]="equipmentCreateTitle.invalid || isLoadingEquipment"
                                                          (click)="addEquipment(modal, form)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="onCancel(form, modal)">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
      
                                              <ng-template #contentEquipmentEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} {{'Equipments' | translate}} #{{equipmentEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="equipmentEdit.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="equipmentEditTitle" #equipmentEditTitle="ngModel"  
                                                              name="equipmentTitle" class="form-control"  required>
                                                          <div [hidden]="equipmentEditTitle.valid || equipmentEditTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="equipmentEdit.description" rows="5" 
                                                              class="form-control" name="equipmentDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="equipmentEdit.comment" rows="5" 
                                                              class="form-control" name="equipmentComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group" *ngIf="equipmentEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of equipmentEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openPreview(filePreviewModal1, 'equipments', fileName, equipmentEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('equipments', fileName, equipmentEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('equipments', fileName, equipmentEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, equipmentEdit)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                      [disabled]="equipmentEditTitle.invalid"
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateEquipment(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                              <button type="button" (click)="open(contentEquipment)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="equipments" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Tile' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openEquipmentEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('equipments', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                      </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="2">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-file-text mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Documents' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="agreements" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Tile' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openAgreementEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.startDate ? (row.startDate | date:'yyyy-MM-dd') : '-'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.endDate ? (row.endDate | date:'yyyy-MM-dd') : '-'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}" prop="type"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('agreements', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="3">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-droplet mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Meters' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="meters" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openMeterEditDialog(row.id)">
                                                      {{row.type}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Category' | translate}}" prop="category"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('meters', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>    
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="4">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-file mr-1"></i>
                                    <span class="d-none d-sm-block"> {{'Contracts' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                          <ngx-datatable class="bootstrap core-bootstrap" 
                                              [rows]="contracts" 
                                              [columnMode]="'force'" 
                                              [headerHeight]="50" 
                                              [footerHeight]="50"
                                              [rowHeight]="'auto'"
                                              [limit]="15">
                                              <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                          <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                              {{row.status}}
                                                          </span>
                                                      </div>                      
                                                      <ng-template #nonActiveBadge>
                                                          <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                              {{row.status}}
                                                          </span>                       
                                                      </ng-template>
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <a class="primary p-0" data-original-title="" (click)="openContractEditDialog(row.id)">
                                                          {{row.startDate | date:'yyyy-MM-dd'}}
                                                      </a>
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      {{row.endDate | date:'yyyy-MM-dd'}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'RentPrice' | translate}}" prop="rentPrice"  ></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'HGMTFee' | translate}}" prop="hgmtFee"></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'Actions' | translate}}">
                                                  <ng-template let-row="row"  ngx-datatable-cell-template>
                                                    <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('rental-contracts', row.id)">
                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                  </ng-template>
                                              </ngx-datatable-column> 
                                          </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="7">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-dollar-sign mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Invoices' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                          <div class="col-3 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                              <h2 class="card-title text-right">
                                                  {{'Balance' | translate}}: {{balance}} PLN
                                              </h2>
                                          </div>   
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="invoices" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                      {{row.status}}
                                                  </span>
                                                  </div>                      
                                                  <ng-template #nonActiveBadge>
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                      {{row.status}}
                                                  </span>                       
                                                  </ng-template>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Title' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openInvoiceEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Actions' | translate}}">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('invoices', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>
                                  
                                  </ng-template>
                                </li>
                                <!--
                                <li [ngbNavItem]="8">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-briefcase mr-1"></i>
                                    <span class="d-none d-sm-block">{{'CostsAareement' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="expenses" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                      <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                      {{row.status}}
                                                  </span>
                                              </div>                      
                                              <ng-template #nonActiveBadge>
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                      {{row.status}}
                                                  </span>                       
                                              </ng-template>
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              <a class="primary p-0" data-original-title="" (click)="openExpensesEditDialog(row.id)">
                                                  {{row.type}}
                                              </a>
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'AccountNumber' | translate}}" prop="accountNumber"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="Actions">
                                          <ng-template let-row="row"  ngx-datatable-cell-template>
                                            <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('costs-of-agreements', row.id)">
                                              <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                            </a>
                                          </ng-template>
                                      </ngx-datatable-column> 
                                      </ngx-datatable> 
                                  </ng-template>
                           
                                </li>-->                        
                            </ul>
                            <div class="tab-content">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>

                        </div><!----./landlord-->

                        <div *ngIf="viewType === 'tenant'">
                            <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-left"> 
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink class="nav-link d-flex align-items-center">
                                        <i class="ft-droplet mr-1"></i>
                                        <span class="d-none d-sm-block">{{'Meters' | translate}}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <div class="row">
                                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                                <ng-template #content let-c="close" let-d="dismiss" let-modal>
                                                    <div class="modal-header">
                                                        <h4 class="modal-title">{{'AddNewMeter' | translate}}</h4>
                                                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <form class="form">
                                                        <div class="form-group">
                                                            <label>{{'Type' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meter.type" 
                                                                name="meterType" #type="ngModel" required>
                                                                <option *ngFor="let type of meterTypes" value="{{type}}">
                                                                    {{type}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Category' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meter.category" 
                                                                name="meterCategory" #category="ngModel" required>
                                                                <option *ngFor="let category of meterCategories" value="{{category}}">
                                                                    {{category}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Value' | translate}} *</label>
                                                            <input  [(ngModel)]="meter.value"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                                                id="meterCreateValue" #meterCreateValue="ngModel" 
                                                                name="meterValue" class="form-control" 
                                                                type="number" required>
                                                            <div [hidden]="meterCreateValue.valid || meterCreateValue.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div> 
                                                        </div>
                                                        <div class="form-group">
                                                            <input id="files" type="file" (change)="handleFileSelection($event, meter)" multiple />
                                                        </div>
                                                        </form>
                                                    </div>
                                                    <div class="modal-footer">                                         
                                                        <button type="submit" 
                                                        [disabled]="meterCreateValue.invalid || isLoading"
                                                        class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="addMeter(modal)">{{'Save' | translate}}</button>
                                                        <button type="button" class="btn btn-secondary" (click)="onCancel(form, modal)">{{'Cancel' | translate}}</button>
                                                    </div>
                                                </ng-template>
                                                <button type="button" (click)="open(content)"
                                                    class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                    {{'Add' | translate}}
                                                </button>
        
                                                <ng-template #contentMeterEdit let-c="close" let-d="dismiss" let-modal>
                                                    <div class="modal-header">
                                                        <h4 class="modal-title">{{'Edit' | translate}} #{{meterEdit.id}}</h4>
                                                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <form class="form">
                                                        <div class="form-group">
                                                            <label>{{'Type' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meterEdit.type" 
                                                                name="meterType" #type="ngModel" required>
                                                                <option *ngFor="let type of meterTypes" value="{{type}}">
                                                                    {{type}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Category' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meterEdit.category" 
                                                                name="meterCategory" #category="ngModel" required>
                                                                <option *ngFor="let category of meterCategories" value="{{category}}">
                                                                    {{category}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Value' | translate}} *</label>
                                                            <input  [(ngModel)]="meterEdit.value"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                                                id="meterUpdateValue" #meterUpdateValue="ngModel"
                                                                name="meterValue" class="form-control" 
                                                                type="number" required>
                                                            <div [hidden]="meterUpdateValue.valid || meterUpdateValue.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div> 
                                                        </div>
                                                        <div class="form-group" *ngIf="meterEdit.fileNames?.length > 0">
                                                            <label>{{'UploadedFiles' | translate}}:</label>
                                                            <ul>
                                                                <li *ngFor="let fileName of meterEdit.fileNames">
                                                                    <span class="fileName cursor-pointer" 
                                                                            (click)="openPreview(filePreviewModal1, 'meters', fileName, meterEdit.id)">
                                                                        {{ fileName }}
                                                                    </span>
                                                                    <a class="danger p-0" data-original-title="" title="download" 
                                                                        (click)="downloadFile('meters', fileName, meterEdit.id)">
                                                                        <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                    </a>
                                                                    <a class="danger p-0" data-original-title="" title="delete" 
                                                                        (click)="removeFile('meters', fileName, meterEdit.id)">
                                                                        <i class="ft-trash cursor-pointer mr-2"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="form-group">
                                                            <input id="files" type="file" (change)="handleFileSelection($event, meterEdit)" multiple />
                                                        </div>
                                                        </form>
                                                    </div>
                                                    <div class="modal-footer">                                         
                                                        <button type="submit" 
                                                        [disabled]="meterUpdateValue.invalid"    
                                                        class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateMeter(modal)">{{'Save' | translate}}</button>
                                                        <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <ngx-datatable class="bootstrap core-bootstrap" 
                                            [rows]="meters" 
                                            [columnMode]="'force'" 
                                            [headerHeight]="50" 
                                            [footerHeight]="50"
                                            [rowHeight]="'auto'"
                                            [limit]="15">
                                            <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <a class="primary p-0" data-original-title="" (click)="openMeterEditDialog(row.id)">
                                                        {{row.type}}
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Category' | translate}}" prop="category"  ></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Actions' | translate}}">
                                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                                    <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('meters', row.id)">
                                                        <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column> 
                                        </ngx-datatable>    
                                    </ng-template>
                                </li>
    
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink class="nav-link d-flex align-items-center">
                                        <i class="ft-file mr-1"></i>
                                        <span class="d-none d-sm-block"> {{'Contracts' | translate}}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ngx-datatable class="bootstrap core-bootstrap" 
                                            [rows]="contracts" 
                                            [columnMode]="'force'" 
                                            [headerHeight]="50" 
                                            [footerHeight]="50"
                                            [rowHeight]="'auto'"
                                            [limit]="15">
                                            <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                        <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                            {{row.status}}
                                                        </span>
                                                    </div>                      
                                                    <ng-template #nonActiveBadge>
                                                        <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                            {{row.status}}
                                                        </span>                       
                                                    </ng-template>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <a class="primary p-0" data-original-title="" (click)="openContractEditDialog(row.id)">
                                                        {{row.startDate | date:'yyyy-MM-dd'}}
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.endDate | date:'yyyy-MM-dd'}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'RentPrice' | translate}}" prop="rentPrice"  ></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'HGMTFee' | translate}}" prop="hgmtFee"></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Actions' | translate}}">
                                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                                    <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('rental-contracts', row.id)">
                                                     <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column> 
                                        </ngx-datatable>  
                                    </ng-template>
                                </li>

                                <li [ngbNavItem]="3">
                                    <a ngbNavLink class="nav-link d-flex align-items-center">
                                        <i class="ft-briefcase mr-1"></i>
                                        <span class="d-none d-sm-block">{{'CostsAareement' | translate}}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ngx-datatable class="bootstrap core-bootstrap" 
                                            [rows]="actualExpenses" 
                                            [columnMode]="'force'" 
                                            [headerHeight]="50" 
                                            [footerHeight]="50"
                                            [rowHeight]="'auto'"
                                            [limit]="15">
                                        <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                    <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                        {{row.status}}
                                                    </span>
                                                </div>                      
                                                <ng-template #nonActiveBadge>
                                                    <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                        {{row.status}}
                                                    </span>                       
                                                </ng-template>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <a class="primary p-0" data-original-title="" (click)="openExpensesEditDialog(row.id)">
                                                    {{row.type}}
                                                </a>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'AccountNumber' | translate}}" prop="accountNumber"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.createTime |date:'yyyy-MM-dd HH:mm'}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Actions' | translate}}">
                                            <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('costs-of-agreements', row.id)">
                                                    <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                            </ng-template>
                                        </ngx-datatable-column> 
                                        </ngx-datatable> 
                                    </ng-template>
                                </li>                        
                            </ul>
                            <div class="tab-content">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div><!----./tenant-->
                    </div><!----./card-body-->

                </div>
            </div>
        </div>
    </div>
</section>