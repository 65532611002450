import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import swal from 'sweetalert2';
import { FlatService } from "./flat.service";
import { Flat } from "app/models/flat/flat";
import { FlatActualContract } from "app/models/flat/flatContract";
import { AuthService } from "app/shared/auth/auth.service";
import { Role } from "app/models/enums/role";
import { InvoiceService } from "./invoice.service";
import { Invoice } from "app/models/invoice/invoice";
import { RentalContractStatus } from "app/models/enums/rentalContractStatus";

@Component({
    selector: 'app-order',
    templateUrl: './flat.component.html',
    providers: [FlatService, InvoiceService]
})
export class FlatComponent implements OnInit {
    
    //items: Flat[];
    items: FlatActualContract[];
    temp = [];
    flats: Flat[];
    invoices: Invoice[];
    userRole: Role | null = null;
    userId: number | null = null;
    viewType: string = '';
    balance: number;
    contractStatus: string[] = Object.keys(RentalContractStatus);
    selectedStatus: string = 'Active';

    constructor(private flatService: FlatService,
                private invoiceService: InvoiceService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService,
                private toastr: ToastrService,
                private authService: AuthService,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.userRole = this.authService.getUserRole();
        this.userId = this.authService.getUserId();
        switch(this.userRole)
        {
            case Role.Admin:
                this.loadData();
                this.viewType = 'admin';
                break;
            case Role.Employee:
                this.loadData();
                this.viewType = 'employee';
                break;
            case Role.Landlord:
                this.loadLandlordFlats(this.userId);
                this.loadLandlordInvoices(this.userId);
                this.viewType = 'landlord';
                break;
            case Role.Tenant:
                this.loadTenantFlats(this.userId);
                this.viewType = 'tenant';
                break;
        }
    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();
    
        // Предварительная фильтрация для исключения пустых полей
        const filteredTemp = this.temp.filter((d) => 
            d.rent != null &&
            d.flat?.internalId != null &&
            d.flat?.title != null &&
            d.flat?.city != null &&
            d.flat?.street != null &&
            d.flat?.building != null &&
            d.flat?.apartment != null &&
            d.flat?.postcode != null &&
            d.actualContract?.tenant?.firstName != null &&
            d.actualContract?.tenant?.lastName != null &&
            d.flat?.landlord?.firstName != null &&
            d.flat?.landlord?.lastName != null &&
            d.actualContract?.endDate != null
        );
    
        // Основной поиск
        const temp = filteredTemp.filter((d) => {
            return (
                d.rent?.toString().toLowerCase().indexOf(val) !== -1 || 
                d.flat?.internalId?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.flat?.title?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.flat?.city?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.flat?.street?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.flat?.building?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.flat?.apartment?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.flat?.postcode?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.actualContract?.tenant?.firstName?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.actualContract?.tenant?.lastName?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.flat?.landlord?.firstName?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.flat?.landlord?.lastName?.toString().toLowerCase().indexOf(val) !== -1 ||
                d.actualContract?.endDate?.toISOString().toLowerCase().indexOf(val) !== -1 || 
                !val
            );
        });
    
        this.items = temp;
        this.cdr.detectChanges();
    }
    

    
    onStatusChange() {
        if (this.selectedStatus === 'all') {
            this.items = [...this.temp];
        } else {
            console.log(this.selectedStatus);
        this.items = this.temp.filter(item => {
            if (!item.actualContract) {
                return this.selectedStatus === 'NonActive';
            }
            return item.actualContract.status === this.selectedStatus;
        });
        }
        this.cdr.detectChanges();
    }

    private loadData(): void {
        this.flatService.getAllWithActualContract().subscribe((data: FlatActualContract[]) => {
            this.items = data;
            this.temp = [...data];
            this.onStatusChange(); 
            this.cdr.detectChanges();
        })
    }

    private delete(id: number): void {
        this.flatService.delete(id).subscribe(data => {
            this.loadData();
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private loadLandlordFlats(id: number) {
        this.flatService.getAllWithActualContractByLandlordId(id).subscribe((data: FlatActualContract[]) => {
            this.items = data;
            this.temp = [...data];
            
        });
    }

    private loadTenantFlats(id: number) {
        this.flatService.getAllByTenantId(id).subscribe((data: Flat[]) => {
            this.flats = data;
            this.temp = [...data];
            this.cdr.detectChanges();
        })
    }

    private loadLandlordInvoices(id: number) {
        this.invoiceService.getAllByLandlordId(id).subscribe((data: Invoice[]) => {
            this.invoices = data;
            this.balance = this.invoices.map(x => x.value).reduce((sum, invoice) => sum + invoice, 0);
            this.cdr.detectChanges();
        })
    }


    viewPage(id: number) {
        this.router.navigate(['/flats/'+ id]);
    }

    viewPageEmployee(id:number){
        this.router.navigate(['/employees/'+ id]);
    }

    viewPageLandlord(id:number){
        this.router.navigate(['/landlords/'+ id]);
    }

    viewPageTenant(id:number){
        this.router.navigate(['/tenants/'+ id]);
    }

    confirmDelete(id: number) {
        swal.fire({
            title: this.translate.instant('DeleteIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.delete(id);
            }
        })
    }
    
}