import { Injectable } from "@angular/core";
import { TransactionStatus } from "../enums/transactionStatus";
import { Adapter } from "app/core/interfaces/adapter";
import { number } from "ngx-custom-validators/src/app/number/validator";

export class TransactionUpdate {
    constructor(
        public id: number,
        public flatId: number,
        public sum?: number,
        public description?: number,
        public internalFlatId?: number,
        public status?: TransactionStatus,
        public exportFilesId?: number,
        public isFlatBalance?: boolean
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class TransactionUpdateAdapter implements Adapter<TransactionUpdate> {
    adapt(item: any): TransactionUpdate {
        return new TransactionUpdate(
            item.id,
            item.flatId,
            item.sum,
            item.description,
            item.internalFlatId,
            item.status,
            item.exportFilesId,
            item.isFlatBalance
        )
    }
}