<section  id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'DocumentManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'DocumentManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-header pb-2">
                        <h4 class="card-title" id="horz-layout-colored-controls">{{'AddDocumentTitle' | translate}}</h4>
                        <p class="mb-0">{{'AddDocumentDescription' | translate}}</p>
                    </div>
                    <div class="card-body">
                        <div class="mt-2">
                            <form class="form" #form="ngForm" (ngSubmit)="create(form)">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Title' | translate}} *</label>
                                                <input [(ngModel)]="item.title" 
                                                    placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                    name="title" #title="ngModel" class="form-control"
                                                    maxlength="50" required>
                                                <div *ngIf="formSubmitted && title.invalid" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                        </div>    
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Description' | translate}} </label>
                                                <textarea [(ngModel)]="item.description" id="bdescription"
                                                    rows="3" class="form-control" name="description"
                                                    #description="ngModel" maxlength="2000"></textarea>
                                            </div>
                                        </div> 
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Comment' | translate}} </label>
                                                <textarea [(ngModel)]="item.comment" id="bdescription" 
                                                    rows="3" class="form-control" name="comment"
                                                    #comment="ngModel" maxlength="2000"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Files' | translate}}: *</label>
                                                <input id="files" type="file" (change)="handleFileSelection($event, item)" multiple />
                                                <div *ngIf="!isFileSelected" class="text-danger">
                                                    {{'FieldRequired' | translate}}
                                                </div>
                                            </div>
                                        </div>                
                                    </div>
                                    <div class="row">   
                                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                            <button type="submit" [disabled]="!isFileSelected" 
                                                class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"> 
                                                {{'Save' | translate}}
                                            </button>
                                            <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                {{'Cancel' | translate}}
                                            </button> 
                                        </div>
                                    </div> 
                                   
                                </div>
                            </form>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>