import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, 
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('start');
    const currentUser = this.authService.currentUser;
    if (currentUser) {
      console.log('user: ', currentUser);
        return true;
    }

    this.router.navigate(['pages/login'], { queryParams: { returnUrl: state.url } });

    return false;
  }
}
