import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { UserStatus } from "../enums/userStatus";

export class LandlordCreate {
    constructor(
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public phoneNumber?: string,
    public status?: UserStatus,
    public password?: string,
    public comment?: string,
    public accountNumber?: string 
    ){
        this.phoneNumber = "+48";
    }
}