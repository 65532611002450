import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import {catchError, map} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Employee } from 'app/models/employee/employee';
import { JwtToken } from 'app/models/token/jwtToken';
import { throwError } from 'rxjs';
import { Role } from 'app/models/enums/role';

@Injectable()
export class AuthService {
  private url = environment.baseApiurl + "/guest";
  
  private currentUserSubject: BehaviorSubject<JwtToken>;
  public currentUser: Observable<JwtToken>;

  constructor(private http: HttpClient, 
              private router: Router,) {
    this.currentUserSubject = new BehaviorSubject<JwtToken>(JSON.parse(localStorage.getItem('currentUser')));
    //this.currentUserSubject = new BehaviorSubject<Employee>(null); for version with JWT
    this.currentUser = this.currentUserSubject.asObservable();
  }

  signupUser(email: string, password: string) {
    // not available
  }

  signinUser(email: string, password: string) {
    return this.http.post<any>(`${this.url}/loginemail`, { email, password }, { withCredentials: true })
                    .pipe(map(user => {
                            localStorage.setItem('currentUser', JSON.stringify(user));
                            this.currentUserSubject.next(user);
                            //this.startRefreshTokenTimer();
                            return user;
                        })); 
  }

  logout() {   
    console.log('start logout');
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    sessionStorage.removeItem('currentUser');
    this.router.navigate(['/pages/login']);
    //this.stopRefreshTokenTimer(); for version with JWT
    //this.currentUserSubject.next(null);

    this.currentUserSubject = new BehaviorSubject<JwtToken>(JSON.parse(localStorage.getItem('currentUser')));
console.log('Current User from localStorage:', localStorage.getItem('currentUser'));

  }

  resetPassword(email: string) {
    return this.http.post(`${this.url}/ResetPassword`, {email})
                .pipe(
                    catchError((err) => {
                        return throwError(err)
                    })
                );
  }


  public get CurrentUserValue(): JwtToken {
    return this.currentUserSubject.value;
  }


  getUserRole(): Role | null {
    const token = this.CurrentUserValue?.accessToken;
    if (!token) {
      return null;
    }

    try {
      const decodedToken: any = this.decodeToken(token);
      console.log('decoded token: ', decodedToken);
      // Assuming the role is stored under the ClaimTypes.Role or a custom claim like 'role'
      const role = decodedToken['role'] || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      return role as Role;  
    } catch (error) {
      console.error('Failed to decode token', error);
      return null;
    }
  }

  getUserId(): number | null {
    const token = this.CurrentUserValue?.accessToken;
    if (!token) {
      return null;
    }

    try {
      const decodedToken: any = this.decodeToken(token);
      const id =  decodedToken['Id'] || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/id'];
      return id;  
    } catch (error) {
      console.error('Failed to decode token', error);
      return null;
    }
  }

  decodeToken(token: string): any {
    const payload = token.split('.')[1]; 
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    const decodedPayload = atob(base64);
    return JSON.parse(decodedPayload);
  }




  /*for version with JWT
  https://jasonwatmore.com/post/2020/05/22/angular-9-jwt-authentication-with-refresh-tokens

  
  refreshToken() {
    return this.http.post<any>(`${this.url}/UpdateAccessToken`, {}, { withCredentials: true })
        .pipe(map((user) => {
            this.currentUserSubject.next(user);
            this.startRefreshTokenTimer();
            return user;
        }));
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
     var currentDate = new Date();
     //const expires = new Date(jwtToken.exp * 1000);
     //const timeout = expires.getTime() - Date.now() - (60 * 1000);
     const timeout = currentDate.setHours(currentDate.getHours() + 1)
     this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
  }
  */
}
