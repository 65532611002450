import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";

export class Document {
    constructor(
        private id: number,
        private createTime: Date,
        private title: string,
        private description: string,
        private comment: string,
        public fileNames?: string[]
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class DocumentAdapter implements Adapter<Document> {
    adapt(item: any): Document {
        return new Document(
            item.id,
            new Date(item.createTime * 1000),
            item.title,
            item.description,
            item.comment,
            item.fileNames
        )
    }
}