import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { EmployeeService } from "./employee.service";
import { Employee, EmployeeAdapter } from "app/models/employee/employee";
import { Role } from "app/models/enums/role";
import { FlatService } from "app/flat/flat.service";
import { Flat } from "app/models/flat/flat";
import { UserStatus } from "app/models/enums/userStatus";
import { NgForm } from "@angular/forms";


@Component( {
    selector: 'app-root',
    templateUrl: './profile-employee.component.html',
    providers: [EmployeeService, FlatService]
})
export class ProfileEmployeeComponent implements OnInit {
    
    item: Employee;
    roles: string[];
    flats: Flat[];
    userStatus: string[];

    formSubmitted = false;

    constructor(private employeeService: EmployeeService,
                private flatService: FlatService,
                private route: ActivatedRoute,
                private router: Router,
                private adapter: EmployeeAdapter,
                private toastr: ToastrService,
                private translate: TranslateService) {

    }
    
    ngOnInit(): void {
        let id: number = Number(this.route.snapshot.paramMap.get("id"));
        this.getById(id);
        this.roles = [Role.Admin, Role.Employee]; //Object.keys(Role);
        this.loadFlats(id);
        this.userStatus = Object.keys(UserStatus);
    }

    private getById(id: number) {
        this.employeeService.getById(id).subscribe((data: Employee) => {
            this.item = data;
        });
    }

    private loadFlats(id: number) {
        this.flatService.getAllByEmployeeId(id).subscribe((data: Flat[]) => {
            this.flats = data;
        })
    }

    update(form: NgForm): void {
        this.formSubmitted = true;
        if (form.valid) {
            let updatedModel = this.adapter.adapt(this.item);
            this.employeeService.update(updatedModel).subscribe(data => {
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            })
        }
    }


    viewPage(id: number) {
        this.router.navigate(['/flats/'+ id]);
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/employees/allemployees']);
            }
        })

    }

}