<section  id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'TenantManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'TenantManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-header pb-2">
                        <h4 class="card-title" id="horz-layout-colored-controls">{{'AddTenantTitle' | translate}}</h4>
                        <p class="mb-0">{{'AddTenantDescription' | translate}}</p>
                    </div>
                    <div class="card-body">
                        <div class="mt-2">
                            <form class="form" #form="ngForm" (ngSubmit)="create(form)">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'FirstName' | translate}} *</label>
                                                <input [(ngModel)]="item.firstName" 
                                                    placeholder="{{'EnterThe' | translate}}{{'FirstName' | translate}}" 
                                                    name="firstName" #firstName="ngModel" class="form-control"
                                                    maxlength="50" required>
                                                <div *ngIf="formSubmitted && firstName.invalid" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                        </div>    
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'LastName' | translate}} *</label>
                                                <input [(ngModel)]="item.lastName" 
                                                    placeholder="{{'EnterThe' | translate}}{{'LastName' | translate}}" name="name" 
                                                    class="form-control" id="lastName" #lastName="ngModel" 
                                                    maxlength="50" required>
                                                <div *ngIf="formSubmitted && lastName.invalid" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Email' | translate}} *</label>
                                                <input type="email" [(ngModel)]="item.email" 
                                                    placeholder="{{'EnterThe' | translate}}{{'Email' | translate}}" name="email" 
                                                    class="form-control" id="email" #email="ngModel" 
                                                    pattern="[a-zA-Z0-9@-_-.]{10,50}" required>
                                                <div *ngIf="formSubmitted && email.invalid" class="text-danger">
                                                    {{'FieldEmailValidation' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'PHONENUMBER' | translate}} *</label>
                                                <input [(ngModel)]="item.phoneNumber" 
                                                    placeholder="{{'EnterThe' | translate}} {{'PhoneNumber' | translate}}" 
                                                    name="phoneNumber" class="form-control" 
                                                    #phoneNumber="ngModel" maxlength="50" required>
                                                <div *ngIf="formSubmitted && phoneNumber.invalid" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                        </div>                  
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Password' | translate}} *</label>
                                                <input [(ngModel)]="item.password" 
                                                    placeholder="{{'EnterThe' | translate}}{{'Password' | translate}}" 
                                                    name="password" class="form-control" id="password" 
                                                    #password="ngModel" required>
                                                <div *ngIf="formSubmitted && password.invalid" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Status' | translate}} *</label>
                                                <select class="form-control" [(ngModel)]="item.status"
                                                    #status="ngModel"   
                                                    name="itemStatus" required>
                                                    <option *ngFor="let status of userStatus" value="{{status}}">
                                                        {{status}}
                                                    </option>
                                                </select>
                                                <div *ngIf="formSubmitted && status.invalid" class="text-danger">
                                                    {{'FieldRequired' | translate}}
                                                </div>
                                            </div>
                                        </div>                                             
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Comment' | translate}} </label>
                                                <textarea [(ngModel)]="item.comment" id="bdescription" rows="3" 
                                                    class="form-control" name="description"
                                                    #comment="ngModel" maxlength="200"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">   
                                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                            <button type="submit"  class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"> 
                                                {{'Save' | translate}}
                                            </button><!--[disabled]="firstName.invalid || lastName.invalid || email.invalid ||
                                            password.invalid" -->
                                            <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                {{'Cancel' | translate}}
                                            </button> 
                                        </div>
                                    </div> 
                                   
                                </div>
                            </form>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>