<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'TenantManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'TenantManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="horz-layout-colored-controls">{{'AllTenantsTitle' | translate}}</h4>
                    <p class="mb-0">{{'AllTenantsDescription' | translate}}</p>
                    <div class="row">
                        <div class="col-md-11">
                            <fieldset class="form-group">
                                <label>{{'Search' | translate}}</label>
                                <input id="ngx-filter-ref" 
                                    class="form-control form-control-sm width-200" 
                                    type="text"
                                    placeholder="{{'FilterTheName' | translate}}" 
                                    (keyup)="updateFilter($event)" />
                            </fieldset> 
                        </div>
                        <div class="col-md-1">
                            <a class="btn btn-outline-primary" href="/tenants/addtenant">{{'Add' | translate}}</a>
                        </div>
                    </div>
                    
                </div>
                <div class="card-content">                  
                    <div class="col-md-12">
                        <ngx-datatable class="bootstrap core-bootstrap" 
                            [rows]="items" 
                            [columnMode]="'force'" 
                            [headerHeight]="50" 
                            [footerHeight]="50"
                            [rowHeight]="'auto'"
                            [limit]="15">
                            <ngx-datatable-column name="#" prop="id" [width]="30"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'FirstName' | translate}}" prop="firstName">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <a class="primary p-0" data-original-title="" (click)="viewPage(row.id)">
                                        {{row.firstName}}
                                    </a>
                                </ng-template> 
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'LastName' | translate}}" prop="lastName"  >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <a class="primary p-0" data-original-title="" (click)="viewPage(row.id)">
                                        {{row.lastName}}
                                    </a>
                                </ng-template> 
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Email' | translate}}" prop="email"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Phone' | translate}}" prop="phoneNumber"></ngx-datatable-column>    
                            <ngx-datatable-column name="{{'Status' | translate}}" prop="status" [width]="70">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                        <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                            {{'UserStatus.' + row.status | translate}}
                                        </span>
                                    </div>                      
                                    <ng-template #nonActiveBadge>
                                        <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                            {{'UserStatus.' + row.status | translate}}
                                        </span>                       
                                    </ng-template>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'CreateTime' | translate}}" prop="createTime">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Actions' | translate}}">
                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                    <ng-container *ngIf="row.status  == 'Active'; else blockUserBtn">                     
                                        <a class="danger p-0" data-original-title="" title="" (click)="confirmBlock(row.id, true)">
                                            <i class="ft-unlock mr-2"></i>
                                        </a>
                                    </ng-container>                      
                                    <ng-template #blockUserBtn>
                                        <a class="danger p-0" data-original-title="" title="" (click)="confirmBlock(row.id, false)">
                                            <i class="ft-lock mr-2"></i>
                                        </a> 
                                    </ng-template>
                                    <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.id)">
                                        <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                    </a>
                                    <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id)">
                                        <i class="ft-trash cursor-pointer mr-2"></i>
                                    </a>
                                </ng-template>
                              </ngx-datatable-column> 
                        </ngx-datatable>    
                    </div>                                                 
                </div>
            </div>
        </div>
    </div>
</section>