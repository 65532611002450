import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';
import { ExportFilesAdapter } from "app/models/exportFiles/exportFiles";
import { TransactionAdapter } from "app/models/transaction/transaction";
import { ExportFilesFull } from "app/models/exportFiles/exportFilesFull";

@Injectable()
export class ExportFilesService {
    private url = environment.baseApiurl + "/ExportFiles";

    constructor(private http: HttpClient, 
                private adapter: ExportFilesAdapter,
                private transactionAdapter: TransactionAdapter) {
    }

    getAll() {
        return this.http.get(this.url + '/getAll')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getAllById(id: number) {
        //return this.http.get(this.url + '/getAllById?id=' + id)
            //.pipe(map((data: any) =>  data));
            return this.http.get(this.url + '/getAllById?id=' + id)
            .pipe(
                map((data: ExportFilesFull) => {
                    data.transactions = data.transactions.map(transaction => 
                        this.transactionAdapter.adapt(transaction)
                    );
                    return data;
                })
            );
    }

    getById(id: number) {
        return this.http.get(this.url + '/getById?id=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }
/*
    create(newExportFiles: ExportFilesCreate) {
        return this.http.post(this.url + '/create', newExportFiles);
    }

    update(updatedExportFiles: ExportFilesUpdate) {
        return this.http.put(this.url + '/update', updatedExportFiles);
    }
*/

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }
}