import { InvoiceStatus } from "../enums/invoiceStatus";

export class InvoiceCreate {
    constructor(
        public files: File[] = [],
        public status?: InvoiceStatus,
        public title?: string,
        public value?: number,
        public description: string = "",
        public comment: string = "",
        public endTime?: number,
        public flatId?: number
    ){}
}