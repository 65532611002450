<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'TransactionManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'TransactionManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="horz-layout-colored-controls">{{'AllTransactionsTitle' | translate}}</h4>
                    <p class="mb-0">{{'AllTransactionsDescription' | translate}}</p>
                    <div class="row">
                        <div class="col-md-11">
                            <fieldset class="form-group">
                                <label>{{'Search' | translate}}</label>
                                <input id="ngx-filter-ref" 
                                    class="form-control form-control-sm width-200" 
                                    type="text"
                                    placeholder="{{'FilterTheName' | translate}}" 
                                    (keyup)="updateFilter($event)" />
                            </fieldset> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <ng-template #contentEdit let-c="close" let-d="dismiss" let-modal>
                                <div class="modal-header">
                                  <h4 class="modal-title">{{'Update' | translate}}</h4>
                                  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <form class="form" #form="ngForm" (ngSubmit)="updateTransaction(form, modal)">
                                    <div class="form-group">
                                        <label>{{'Sum' | translate}} *</label>
                                        <input  [(ngModel)]="itemEdit.sum"   
                                            placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                            id="itemSum" #itemSum="ngModel" 
                                            name="itemSum" class="form-control" 
                                            type="number" readonly>
                                        <div [hidden]="itemSum.valid || itemSum.untouched" class="text-danger">
                                            {{'FieldRequired' | translate}}
                                        </div> 
                                    </div>
                                    <div class="form-group">
                                        <label>{{'Description' | translate}} </label>
                                        <textarea [(ngModel)]="itemEdit.description" rows="5" 
                                            class="form-control" name="itemDescription"
                                            maxlength="200" readonly></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>{{'Status' | translate}} *</label>
                                        <select [(ngModel)]="itemEdit.status"
                                            #status="ngModel" 
                                            name="transactionStatus" class="form-control"  required>
                                            <option *ngFor="let status of transactionStatus" value="{{status}}">
                                                {{'TransactionStatus.' + status | translate}}
                                            </option>
                                        </select>
                                        <div *ngIf="formSubmitted && status.invalid" class="text-danger">
                                            {{'FieldRequired' | translate}}
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="itemEdit.status !== 'Removed'">
                                        <label>{{'Flat' | translate}} *</label>                                  
                                        <select class="form-control"  [(ngModel)]="itemEdit.flatId" 
                                                name="flatId" #flatId="ngModel" 
                                                [required]="itemEdit.status !== 'Removed' && itemEdit.status !== 'Error'">
                                            <option *ngFor="let flat of flats" value="{{flat.id}}" > 
                                                InternalID#{{flat.internalId}} {{flat.title}} [{{flat.id}}]
                                            </option>
                                        </select>
                                        <div *ngIf="formSubmitted && status.invalid" class="text-danger">
                                            {{'FieldRequired' | translate}}
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="itemEdit.status === 'CompletedManually'">
                                        <div class="form-group">
                                            <label>{{ itemEdit.isFlatBalance ? ('CreditToLandlordAccount' | translate ) : ('CreditToTenantAccount' | translate) }}
                                               </label>
                                            <div class="form-group">
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" 
                                                        [(ngModel)]="itemEdit.isFlatBalance"
                                                        class="custom-control-input"
                                                        id="isFlatBalance"
                                                        (change)="toggleCredit()"/>
                                                    <label class="custom-control-label" for="isFlatBalance"></label>
                                                </div>
                                                <!--<div *ngIf="!itemEdit.creditToTenant" class="text-muted mt-2">
                                                    {{ 'CreditToLandlordAccount' | translate }}  {{'CreditToTenantAccount' | translate}}
                                                </div>  -->                                 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">                                         
                                        <button type="submit" 
                                              class="btn btn-primary mb-2 mb-sm-0 mr-sm-2">{{'Save' | translate}}</button>
                                        <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                    </div>
                                  </form>
                                </div>
                            </ng-template>
                        </div>
                    </div>                  
                </div>
                <div class="card-content">                  
                    <div class="col-md-12">
                        <ngx-datatable class="bootstrap core-bootstrap" 
                            [rows]="items" 
                            [columnMode]="'force'" 
                            [headerHeight]="50" 
                            [footerHeight]="50"
                            [rowHeight]="'auto'"
                            [limit]="15">
                            <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Sum' | translate}}" prop="sum">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <a class="primary p-0" data-original-title="" (click)="openTransactionEditDialog(row.id)">
                                        {{row.sum}}
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>   
                            <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <div *ngIf="row.status == 'Error'; else successBadge">
                                        <span _ngcontent-ykd-c258="" class="badge bg-light-danger mb-1">
                                            {{'TransactionStatus.' + row.status | translate}}
                                        </span>
                                    </div>                      
                                    <ng-template #successBadge>
                                        <div *ngIf="row.status == 'CompletedAuto'; else secondaryBadge">
                                            <span class="badge bg-light-success mb-1">
                                                {{'TransactionStatus.' + row.status | translate}}
                                            </span>
                                        </div>                       
                                    </ng-template>
                                    <ng-template #secondaryBadge>
                                        <div *ngIf="row.status == 'Removed'; else removedBadge">
                                            <span class="badge bg-light-warning mb-1">
                                                {{'TransactionStatus.' + row.status | translate}}
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #removedBadge>
                                        <span class="badge bg-light-secondary mb-1">
                                            {{'TransactionStatus.' + row.status | translate}}
                                        </span>
                                    </ng-template>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'InternalId' | translate}}">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <a class="primary p-0" data-original-title="" title="edit" (click)="viewFlatPage(row.flatId)">
                                        {{row.internalFlatId}}
                                    </a> 
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'ExportFile' | translate}}" prop="exportFilesId">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <a class="primary p-0" data-original-title="" title="edit" (click)="viewFilePage(row.exportFilesId)">
                                        {{row.exportFilesId}}
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Description' | translate}}" prop="description"></ngx-datatable-column>    
                            <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'PaymentTime' | translate}}" >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.paymentTime | date:'yyyy-MM-dd HH:mm'}}
                                </ng-template>
                            </ngx-datatable-column>
                            
                            <ngx-datatable-column name="{{'Actions' | translate}}">
                                <ng-template let-row="row"  ngx-datatable-cell-template>                     
                                    <a class="danger p-0" data-original-title="" title="edit" (click)="openTransactionEditDialog(row.id)">
                                        <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                    </a><!--
                                    <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id)">
                                        <i class="ft-trash cursor-pointer mr-2"></i>
                                    </a>-->
                                </ng-template>
                              </ngx-datatable-column> 
                        </ngx-datatable>    
                    </div>                                                 
                </div>
            </div>
        </div>
    </div>
</section>