<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'ExportFilesManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'ExportFilesManagerDescription' | translate}}</p>
        </div>
    </div>
    <div *ngIf="isLoading" class="loader-container">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="horz-layout-colored-controls">{{'AllExportFilessTitle' | translate}}</h4>
                    <p class="mb-0">{{'AllExportFilessDescription' | translate}}</p>
                    <div class="row">
                        <div class="col-md-10">
                            <fieldset class="form-group">
                                <label>{{'Search' | translate}}</label>
                                <input id="ngx-filter-ref" 
                                    class="form-control form-control-sm width-200" 
                                    type="text"
                                    placeholder="{{'FilterTheName' | translate}}" 
                                    (keyup)="updateFilter($event)" />
                            </fieldset>
                        </div>
                        <div class="col-md-2 d-flex justify-content-end align-items-center">
                            <a class="btn btn-sm bg-light-secondary mr-2 px-3 py-1" (click)="viewPageTransactions()">
                                {{'Transactions' | translate}}
                            </a>
                            <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none" />
                            <a class="btn btn-outline-primary" (click)="onClickUpload()">{{'Add' | translate}}</a>
                        </div>
                    </div>
                      
                </div>
                <div class="card-content">                  
                    <div class="col-md-12">  
                        <ngx-datatable class="bootstrap core-bootstrap" 
                            [rows]="items" 
                            [columnMode]="'force'" 
                            [headerHeight]="50" 
                            [footerHeight]="50"
                            [rowHeight]="'auto'"
                            [limit]="15">
                            <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'FileName' | translate}}" prop="fileName">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    <a class="primary p-0" data-original-title="" (click)="viewPage(row.id)">
                                        {{row.fileName}}
                                    </a>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Count' | translate}}" prop="countAll"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Successful' | translate}}" prop="countSuccessful"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Hash' | translate}}" prop="hash"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.createTime | date:'yyyy-MM-dd HH:mm'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="{{'Actions' | translate}}">
                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                    <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.id)">
                                        <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                    </a>
                                    <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id)">
                                        <i class="ft-trash cursor-pointer mr-2"></i>
                                    </a>
                                </ng-template>
                            </ngx-datatable-column> 
                        </ngx-datatable>    
                    </div>                                                 
                </div>
            </div>
        </div>
    </div>
</section>
