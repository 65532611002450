import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { DocumentService } from "./document.service";
import { DocumentCreate } from "app/models/document/documentCreate";
import { NgForm } from "@angular/forms";


@Component({
    selector: 'app-root',
    templateUrl: './add-document.component.html',
    providers: [DocumentService]
})
export class AddDocumentComponent implements OnInit {
    
    item: DocumentCreate = new DocumentCreate();
    selectedFiles: File[] = [];
    isFileSelected = false;

    formSubmitted = false;

    constructor(private documentService: DocumentService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
    }

    handleFileSelection(event: any, item: any) {
        if (event.target.files) {
            item.files = Array.from(event.target.files);
            this.isFileSelected = true; 
        } else {
            item.files = [];
            this.isFileSelected = false; 
        }
    }
    
    create(form: NgForm): void { 
        this.formSubmitted = true;
        if (form.valid) {
            this.documentService.create(this.item).subscribe(data => {
                this.router.navigate(['/documents/alldocuments']);
            })
        }
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/documents/alldocuments']);
            }
        })
    }

}