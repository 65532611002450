import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import swal from 'sweetalert2';
import { TransactionService } from "./transaction.service";
import { Transaction } from "app/models/transaction/transaction";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TransactionStatus } from "app/models/enums/transactionStatus";
import { Flat } from "app/models/flat/flat";
import { FlatService } from "app/flat/flat.service";
import { TransactionUpdateAdapter } from "app/models/transaction/transactionUpdate";
import { NgForm } from "@angular/forms";

@Component({
    selector: 'app-order',
    templateUrl: './transaction.component.html',
    providers: [TransactionService, FlatService]
})
export class TransactionComponent implements OnInit {
    
    items: Transaction[];
    temp = [];
    itemEdit: Transaction;
    transactionStatus: string[];
    flats: Flat[];
    //creditToTenant: boolean = true;
    formSubmitted = false;
    test: any; //delete it after test

    closeResult: string;
    @ViewChild('contentEdit') contentEdit: TemplateRef<any>;

    constructor(private transactionService: TransactionService,
                private flatService: FlatService,
                private adapterTransaction: TransactionUpdateAdapter,
                private modalService: NgbModal,
                private router: Router,
                private translate: TranslateService,
                private toastr: ToastrService,
                private cdr: ChangeDetectorRef) {

    }

    ngOnInit(): void {
        this.loadData();
        this.loadFlats();
        this.transactionStatus = [TransactionStatus.Error, TransactionStatus.CompletedManually, TransactionStatus.Removed];
    }


    private loadData(): void {
        this.transactionService.getAll().subscribe((data: Transaction[]) => {
            this.temp = [...data];
            this.items = data;
            this.cdr.detectChanges();
        })
    }

    private loadFlats(): void {
        this.flatService.getAll().subscribe((data: Flat[]) => {
            this.flats = data;
        })  
    }

    openTransactionEditDialog(id:number): void {
        this.modalService.open(this.contentEdit);
        this.getById(id);
        this.cdr.detectChanges();
    }

    private getById(id: number): void {
        this.transactionService.getById(id).subscribe((data: Transaction) => {
            this.itemEdit = data;
        });
    }

    updateTransaction(form: NgForm, modal: NgbModalRef): void {
        this.formSubmitted = true;
        if (form.valid) {
            let updatedTransaction = this.adapterTransaction.adapt(this.itemEdit);
            this.transactionService.update(updatedTransaction).subscribe(data => {
                this.loadData();
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                modal.close(); 
            })
        }
    }

    toggleCredit() {
        this.itemEdit.isFlatBalance = !this.itemEdit.isFlatBalance;
        this.cdr.detectChanges();
    }

    ///WARNING! ADD THE LINK TO FILE
    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            const sum = d.sum ? d.sum.toString() : '';
            const description = d.description ? d.description.toLowerCase() : '';
            const internalId = d.internalFlatId ? d.internalFlatId.toString() : '';
            const id = d.id ? d.id.toString() : '';

        return sum.indexOf(val) !== -1 ||
               description.indexOf(val) !== -1 ||
               internalId.indexOf(val) !== -1 ||
               id.indexOf(val) !== -1 || !val;
        });

        this.items = temp;
    }

    viewPage(id: number) {
        this.router.navigate(['/transactions/'+ id]);
    }

    viewFlatPage(id: number) {
        this.router.navigate(['/flats/'+ id]);
    }

    viewFilePage(id: number) {
        this.router.navigate(['/exportFiles/'+ id]);
    }

    private delete(id: number): void {
        this.transactionService.delete(id).subscribe(data => {
            this.loadData();
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    confirmDelete(id: number) {
        swal.fire({
            title: this.translate.instant('DeleteIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.delete(id);
            }
        })
    }
    
}