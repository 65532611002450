import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { RentalContractStatus } from "../enums/rentalContractStatus";

export class RentalContractUpdate {
    constructor(
        public id: number,
        public status?: RentalContractStatus,
        public startDate?: number,
        public endDate?: number,
        public rentPrice?: number,
        public hgmtFee?: number,
        public files?: File[],
        public employeeId?: number
    ) {}
}

@Injectable( {
    providedIn: 'root'
})
export class RentalContractUpdateAdapter implements Adapter<RentalContractUpdate> {
    adapt(item: any): RentalContractUpdate {
       return new RentalContractUpdate(
            item.id,
            item.status,
            item.startDate,
            item.endDate,
            item.rentPrice,
            item.hgmtFee,
            item.files || [],
            item.employeeId
       )
    }

}