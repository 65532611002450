import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { FlatService } from "./flat.service";
import { Flat, FlatAdapter } from "app/models/flat/flat";
import { Role } from "app/models/enums/role";
import { MeterService } from "./meter.service";
import { AgreementService } from "./agreement.service";
import { EquipmentService } from "./equipment.service";
import { InspectionService } from "./inspection.service";
import { Meter } from "app/models/meter/meter";
import { Inspection } from "app/models/inspection/inspection";
import { Equipment } from "app/models/equipment/equipment";
import { Agreement } from "app/models/agreement/agreement";
import { RentalContractService } from "./rentalcontract.service";
import { RentalContract, RentalContractAdapter } from "app/models/rentalContract/rentalContract";
import { LandlordService } from "app/landlord/landlord.service";
import { Landlord } from "app/models/landlord/landlord";
import { FlatUpdateAdapter } from "app/models/flat/flatUpdate";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MeterCreate } from "app/models/meter/meterCreate";
import { MeterType } from "app/models/enums/meterType";
import { MeterCategory } from "app/models/enums/meterCategory";
import { AgreementCreate, AgreementCreateAdapter } from "app/models/agreement/agreementCreate";
import { DocumentCreate } from "app/models/document/documentCreate";
import { AgreementType } from "app/models/enums/agreementType";
import { RentalContractCreate } from "app/models/rentalContract/rentalContractCreate";
import { RentalContractStatus } from "app/models/enums/rentalContractStatus";
import { Tenant } from "app/models/tenant/tenant";
import { Employee } from "app/models/employee/employee";
import { TenantService } from "app/tenant/tenant.service";
import { EmployeeService } from "app/employee/employee.service";
import { InvoiceService } from "./invoice.service";
import { ExpensesService } from "./expenses.service";
import { Expenses } from "app/models/expenses/expenses";
import { Invoice } from "app/models/invoice/invoice";
import { InvoiceStatus } from "app/models/enums/invoiceStatus";
import { ExpensesType } from "app/models/enums/expensesType";
import { ExpensesStatus } from "app/models/enums/expensesStatus";
import { ExpensesCreate } from "app/models/expenses/expensesCreate";
import { InvoiceCreate } from "app/models/invoice/invoiceCreate";
import { MeterAdapterUpdate, MeterUpdate } from "app/models/meter/meterUpdate";
import { EquipmentUpdate, EquipmentUpdateAdapter } from "app/models/equipment/equipmentUpdate";
import { AgreementUpdate, AgreementUpdateAdapter } from "app/models/agreement/agreementUpdate";
import { RentalContractUpdate, RentalContractUpdateAdapter } from "app/models/rentalContract/rentalContractUpdate";
import { InvoiceAdapterUpdate, InvoiceUpdate } from "app/models/invoice/invoiceUpdate";
import { ExpensesUpdate, ExpensesUpdateAdapter } from "app/models/expenses/expensesUpdate";
import { date } from "ngx-custom-validators/src/app/date/validator";
import { EquipmentCreate } from "app/models/equipment/equipmentCreate";
import { FileService } from "./file.service";
import { forkJoin } from "rxjs";
import { FlatActualContract } from "app/models/flat/flatContract";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthService } from "app/shared/auth/auth.service";
import { TransactionService } from "app/transaction/transaction.service";
import { Transaction } from "app/models/transaction/transaction";
import { TransactionStatus } from "app/models/enums/transactionStatus";
import { TransactionUpdateAdapter } from "app/models/transaction/transactionUpdate";
import { NgForm } from "@angular/forms";


@Component( {
    selector: 'app-root',
    templateUrl: './profile-flat.component.html',
    styleUrls: ['./flat.component.css'],
    providers: [FlatService, MeterService, AgreementService, 
                EquipmentService, InspectionService, RentalContractService, 
                LandlordService, TenantService, EmployeeService, 
                InvoiceService, ExpensesService, FileService, TransactionService]
})
export class ProfileFlatComponent implements OnInit {
    
    item: Flat;
    roles: string[];
    meters: Meter[];
    inspections: Inspection[];
    equipments: Equipment[];
    agreements: Agreement[];
    contracts: RentalContract[];
    landlords: Landlord[];
    currentLandlordId: number;
    tenants: Tenant[];
    employees: Employee[];
    expenses: Expenses[];
    invoices: Invoice[];
    actualExpenses: Expenses[];
    transactions: Transaction[];
    flats: Flat[];

    isLoadingInspection = false;
    isLoadingEquipment = false;
    isLoadingMeter = false;
    isLoadingAgreement = false;
    isLoadingExpenses = false;
    isLoadingInvoices = false;
    isLoadingContract = false;
    closeResult: string;
    id: number;
    meterTypes: string[];
    meterCategories: string[];
    agreementType: string[];
    contractStatus: string[];
    invoiceStatus: string[];
    expensesStatus: string[];
    expensesType: string[];
    transactionStatus: string[];
    balance: number;
    contractEmployeeId: number;
    contractTenantId: number;

    selectedFiles: File[] = [];

    meter: MeterCreate = new MeterCreate();
    equipment: EquipmentCreate = new EquipmentCreate();
    agreement: AgreementCreate = new AgreementCreate();
    inspection: Inspection = new Inspection();
    contract: RentalContractCreate = new RentalContractCreate();
    expense: ExpensesCreate = new ExpensesCreate();
    invoice: InvoiceCreate = new InvoiceCreate();

    @ViewChild('contentMeterEdit') contentMeterEdit: TemplateRef<any>;
    @ViewChild('contentEquipmentEdit') contentEquipmentEdit: TemplateRef<any>;
    @ViewChild('contentAgreementEdit') contentAgreementEdit: TemplateRef<any>;
    @ViewChild('contentContractEdit') contentContractEdit: TemplateRef<any>;
    @ViewChild('contentInvoiceEdit') contentInvoiceEdit: TemplateRef<any>;
    @ViewChild('contentExpensesEdit') contentExpensesEdit: TemplateRef<any>;
    @ViewChild('contentTransactionEdit') contentTransactionEdit: TemplateRef<any>;
    
    meterEdit: MeterUpdate;
    equipmentEdit: EquipmentUpdate;
    agreementEdit: Agreement;
    contractEdit: RentalContract;
    invoiceEdit: Invoice;
    expensesEdit: Expenses;
    transactionEdit: Transaction;

    showModal = false;
    modalFileName = '';
    sanitizedUrl: SafeResourceUrl | null = null;
    userRole: Role | null = null;
    viewType: string = '';
    userId: number | null = null;
    isFileContractSelected = false;

    constructor(private FlatService: FlatService,
                private meterService: MeterService,
                private inspectionService: InspectionService,
                private equipmentService: EquipmentService,
                private agreementService: AgreementService,
                private contractService: RentalContractService,
                private landlordService: LandlordService,
                private tenantService: TenantService,
                private employeeService: EmployeeService,
                private invoiceService: InvoiceService,
                private expensesService: ExpensesService,
                private transactionService: TransactionService,
                private route: ActivatedRoute,
                private router: Router,
                private adapter: FlatUpdateAdapter,
                private adapterMeter: MeterAdapterUpdate,
                private adapterEquipment: EquipmentUpdateAdapter,
                private adapterAgreement: AgreementUpdateAdapter,
                private adapterContract: RentalContractUpdateAdapter,
                private adapterInvoice: InvoiceAdapterUpdate,
                private adapterExpenses: ExpensesUpdateAdapter,
                private adapterTransaction: TransactionUpdateAdapter,
                private toastr: ToastrService,
                private translate: TranslateService,
                private modalService: NgbModal,
                private agreementAdapter: AgreementCreateAdapter,
                private fileService: FileService,
                private cdr: ChangeDetectorRef,
                private sanitizer: DomSanitizer,
                private authService: AuthService) {

    }

    openFilePreview(itemName: string, fileName: string, id: number) {
        this.fileService.getFile(itemName, fileName, id).subscribe(blob => {
          const url = window.URL.createObjectURL(blob);
          const newWindow = window.open('', '_blank');
      
          if (newWindow) {
            newWindow.document.open();
            newWindow.document.write(`
              <html>
                <head><title>${fileName}</title></head>
                <body>
                  <iframe src="${url}" style="width:100%; height:100%; border:none;" frameborder="0"></iframe>
                </body>
              </html>
            `);
            newWindow.document.close(); // Закрыть документ для рендеринга
          } else {
            console.error('Unable to open new window for file preview');
          }
      
          // Очистить URL после загрузки
          newWindow.onload = () => {
            window.URL.revokeObjectURL(url);
          };
        }, error => {
          console.error('File preview failed', error);
        });
    }

    closeModal() {
        this.showModal = false;
        this.sanitizedUrl = null;
    }


    // Open default modal
    open(content) {
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed`;
        });
    }

    handleFileSelection(event: any, item: any) {
        if (event.target.files) {
            item.files = Array.from(event.target.files);
            this.isFileContractSelected = true; 
        } else {
            item.files = [];
            this.isFileContractSelected = false; 
        }
    }

    resetForm(form: NgForm) {
        form.resetForm();
        this.selectedFiles = [];
        this.sanitizedUrl = null; 
    }

    onCancel(form: NgForm, modal: any): void {
        this.resetForm(form);
        modal.close(); 
    }

    private formatDate(item: any, dateProperties: string[]): any {
        if (item) {
            dateProperties.forEach((prop) => {
                if (item[prop]) {
                    const date = new Date(item[prop]);
                    item[prop] = date.toISOString().split('T')[0];
                    console.log(`Formatted ${prop}:`, item[prop]);
                }
            });
        }
        return item;
    }


    addMeter(modal: NgbModalRef, form: NgForm): void {
        if (this.isLoadingMeter) {
            return;
        }
        this.isLoadingMeter = true;
        this.meter.flatId = this.id;
        this.meterService.create(this.meter).subscribe({
            next: (data)  => {
                this.loadMeters(this.id);
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                modal.close();
                this.resetForm(form);
            },
            error: (error) => {
                //this.toastr.error(this.translate.instant('ErrorOccured'), this.translate.instant('Error'), { closeButton: true });
                console.log(error);
                this.isLoadingMeter = false;
            },
            complete: () => {
                this.isLoadingMeter = false;
            }    
        });
    }


    addEquipment(modal: NgbModalRef, form: NgForm):void {
        if (this.isLoadingEquipment) {
            return;
        }

        this.isLoadingEquipment = true;
        this.equipment.flatId = this.id;
        this.equipmentService.create(this.equipment).subscribe({
            next: (data) => {
                this.loadEquipments(this.id);
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                modal.close();
                this.resetForm(form);    
            },
            error: (error) => {
                //this.toastr.error(this.translate.instant('ErrorOccured'), this.translate.instant('Error'), { closeButton: true });
                console.log(error);
                this.isLoadingEquipment = false; // Reset isLoading on error
            },
            complete: () => {
                this.isLoadingEquipment = false; // Reset the loading state after completion
            }
        });
    }

    addAgreement(modal: NgbModalRef, form: NgForm):void {
        if (this.isLoadingAgreement) {
            return;
        }
    
        this.isLoadingAgreement = true;
        this.agreement.flatId = this.id;
        let updatedAgreement = this.agreementAdapter.adapt(this.agreement);
        this.agreementService.create(updatedAgreement).subscribe({
            next: (data) => {
                this.loadAgreements(this.id);
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                modal.close();
                this.resetForm(form);    
            }, 
            error: (error) => {
                //this.toastr.error(this.translate.instant('ErrorOccured'), this.translate.instant('Error'), { closeButton: true });
                console.log(error);
                this.isLoadingAgreement = false;
            }, 
            complete: () => {
                this.isLoadingAgreement = false;
            }
        });
    }

    addInspection(modal: NgbModalRef, form: NgForm):void {
        if (this.isLoadingInspection) {
            return;
        }
    
        this.isLoadingInspection = true;
        this.inspection.flatId = this.id;
        this.inspectionService.create(this.inspection).subscribe({
            next: (data) => {
                this.loadInspections(this.id);
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                modal.close();
                this.resetForm(form);    
            }, 
            error: (error) => {
                //this.toastr.error(this.translate.instant('ErrorOccured'), this.translate.instant('Error'), { closeButton: true });
                console.log(error);
                this.isLoadingInspection = false;
            }, 
            complete: () => {
                this.isLoadingInspection = false;
            }
        });
    }

    addContract(modal: NgbModalRef, form: NgForm): void {
        if (this.isLoadingContract) {
            return;
        }
    
        this.isLoadingContract = true;
        this.contract.flatId = this.id;
        const contractToSend = { ...this.contract };
        contractToSend.startDate = new Date(this.contract.startDate).getTime() / 1000;
        contractToSend.endDate = new Date(this.contract.endDate).getTime() / 1000;
        this.contractService.create(contractToSend).subscribe({
            next: (data) => {
                this.loadContracts(this.id);
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                modal.close();
                this.resetForm(form);   
            },
            error: (error) => {
                //this.toastr.error(this.translate.instant('ErrorOccured'), this.translate.instant('Error'), { closeButton: true });
                console.log(error);
                this.isLoadingContract = false;
            },
            complete: () => {
                this.isLoadingContract = false;
            }
        });
    }

    addExpenses(modal: NgbModalRef, form: NgForm): void {
        if (this.isLoadingExpenses) {
            return;
        }
    
        this.isLoadingExpenses = true;
        this.expense.flatId = this.id;
        this.expense.startDate = new Date(this.expense.startDate).getTime() / 1000;
        this.expensesService.create(this.expense).subscribe({
            next: (data) => {
                this.loadExpenses(this.id);
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                modal.close();
                this.resetForm(form);
            },
            error: (error) => {
                //this.toastr.error(this.translate.instant('ErrorOccured'), this.translate.instant('Error'), { closeButton: true });
                console.log(error);
                this.isLoadingExpenses = false;
            },
            complete: () => {
                this.isLoadingExpenses = false;
            }
        })
    }

    addInvoice(modal: NgbModalRef, form: NgForm): void {
        if (this.isLoadingInvoices) {
            return;
        }
    
        this.isLoadingInvoices = true;
        this.invoice.flatId = this.id;
        this.invoice.endTime = new Date(this.invoice.endTime).getTime() / 1000;
        this.invoiceService.create(this.invoice).subscribe({
            next: (data) => {
                this.loadInvoices(this.id);
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                modal.close();
                this.resetForm(form);
            },
            error: (error) => {
                //this.toastr.error(this.translate.instant('ErrorOccured'), this.translate.instant('Error'), { closeButton: true });
                console.log(error);
                this.isLoadingInvoices = false;
            },
            complete: () => {
                this.isLoadingInvoices = false;
            }
        })
    }

    openMeterEditDialog(id:number): void {
        this.modalService.open(this.contentMeterEdit);
        /*this.meterService.getById(id).subscribe((data: Meter) => {
            this.meterEdit = data;
        })*/
        this.loadMeter(id);
    }

    private loadMeter(id: number): void {
        this.meterService.getById(id).subscribe((data: Meter) => {
            this.meterEdit = data;
        })
    }

    updateMeter(modal: NgbModalRef): void {
        let updatedMeter = this.adapterMeter.adapt(this.meterEdit);
        this.meterService.update(updatedMeter).subscribe(data => {
            this.loadMeters(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            modal.close();
        })
    }

    openEquipmentEditDialog(id:number): void {
        this.modalService.open(this.contentEquipmentEdit);
        /*this.equipmentService.getById(id).subscribe((data: Equipment) => {
            this.equipmentEdit = data;
        })*/
        this.loadEquipment(id);
    }

    private loadEquipment(id: number): void {
        this.equipmentService.getById(id).subscribe((data: Equipment) => {
            this.equipmentEdit = data;
        })
    }

    updateEquipment(modal: NgbModalRef): void {
        let updatedEquipment = this.adapterEquipment.adapt(this.equipmentEdit);
        this.equipmentService.update(updatedEquipment).subscribe(data => {
            this.loadEquipments(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            modal.close();
        })
    }

    openAgreementEditDialog(id:number): void {
        this.modalService.open(this.contentAgreementEdit);
        this.loadAgreement(id);
    }

    private loadAgreement(id: number) {
        this.agreementService.getById(id).subscribe((data: Agreement) => {
            this.agreementEdit = this.formatDate(data, ['startDate','endDate']);
        });
    }

    updateAgreement(modal: NgbModalRef): void {
        let updatedAgreement = this.adapterAgreement.adapt(this.agreementEdit);
        updatedAgreement.startDate = updatedAgreement.startDate ? new Date(this.agreementEdit.startDate).getTime() / 1000 : null;
        updatedAgreement.endDate = updatedAgreement.endDate ? new Date(this.agreementEdit.endDate).getTime() / 1000 : null;
        this.agreementService.update(updatedAgreement).subscribe(data => {
            this.loadAgreements(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            modal.close();
        })
    }

    openContractEditDialog(id:number): void {
        this.modalService.open(this.contentContractEdit);
        this.loadContract(id);
    }

    private loadContract(id: number) {
        this.contractService.getById(id).subscribe((data: RentalContract) => {
            this.contractEdit = this.formatDate(data, ['startDate','endDate']);
            this.contractEmployeeId = this.contractEdit.employee.id;
            this.contractTenantId = this.contractEdit.tenant.id;
        })
    }

    updateContract(modal: NgbModalRef): void {
        let updatedContract = this.adapterContract.adapt(this.contractEdit);
        updatedContract.startDate = new Date(this.contractEdit.startDate).getTime() / 1000;
        updatedContract.endDate = new Date(this.contractEdit.endDate).getTime() / 1000;
        this.contractService.update(updatedContract).subscribe(data => {
            this.loadContracts(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            modal.close();
        })
    }

    openInvoiceEditDialog(id: number): void {
        this.modalService.open(this.contentInvoiceEdit);
        this.loadInvoice(id);
    }

    private loadInvoice(id: number) {
        this.invoiceService.getById(id).subscribe((data: Invoice) => {
            this.invoiceEdit = this.formatDate(data, ['endTime']);
        });
    }

    openTransactionEditDialog(id:number): void {
        this.modalService.open(this.contentTransactionEdit);
        this.loadTransaction(id);
    }

    private loadTransaction(id: number) {
        this.transactionService.getById(id).subscribe((data: Transaction) => {
            this.transactionEdit = data;
        });
    }

    private loadFlats(): void {
        this.FlatService.getAll().subscribe((data: Flat[]) => {
            this.flats = data;
        })
    }

    updateTransaction(modal: NgbModalRef): void {
        console.log('start');
        let updatedTransaction = this.adapterTransaction.adapt(this.transactionEdit);
        this.transactionService.update(updatedTransaction).subscribe(data => {
            this.loadTransactions(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            modal.close();
        })
    }

    removeFile(itemName: string, fileName: string, id: number): void {
        this.fileService.deleteFile(itemName, fileName, id).subscribe(() => {
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            switch(itemName) {
                case 'meters':
                    this.loadMeter(id);
                    break;
                case 'equipments':
                    this.loadEquipment(id);
                    break;
                case 'agreements':
                    this.loadAgreement(id);
                    break;
                case 'invoices':
                    this.loadInvoice(id);
                    break;
                case 'costs-of-agreements':
                    this.loadExpense(id);
                    break;
                case 'rental-contracts':
                    this.loadContract(id);
                    break;
            }
        });
    }

    updateInvoice(modal: NgbModalRef): void {
        let updatedInvoice = this.adapterInvoice.adapt(this.invoiceEdit);
        updatedInvoice.endTime = new Date(this.invoiceEdit.endTime).getTime() / 1000;
        console.log(updatedInvoice);
        this.invoiceService.update(updatedInvoice).subscribe(data => {
            this.loadInvoices(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            modal.close();
        })
    }

    openExpensesEditDialog(id: number): void {
        this.modalService.open(this.contentExpensesEdit);
        this.loadExpense(id);
    }

    private loadExpense(id: number) {
        this.expensesService.getById(id).subscribe((data: Expenses) => {
            this.expensesEdit = this.formatDate(data, ['startDate']);
        })
    }

    updateExpenses(modal: NgbModalRef): void {
        let updatedExpenses = this.adapterExpenses.adapt(this.expensesEdit);
        updatedExpenses.startDate = new Date(this.expensesEdit.startDate).getTime() / 1000;
        this.expensesService.update(updatedExpenses).subscribe(data => {
            this.loadExpenses(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            modal.close();
        })
    }

    downloadFile(itemName: string, fileName: string, id: number): void {
        this.fileService.getFile(itemName, fileName, id)
            .subscribe((blob: Blob) => { 
                this.fileService.saveFile(blob, fileName); }, error => {
                    this.toastr.info(this.translate.instant('Info') + ' I701', this.translate.instant('err701FileNotFound'), { closeButton: true });
                                            console.error('Download failed:', error);
                                        });
    }

    downloadArchive(itemName: string, id: number): void {
        this.fileService.getArhive(itemName, id)
            .subscribe((blob: Blob) => { this.fileService.saveFile(blob, 'archive'); }, error => {
                this.toastr.info(this.translate.instant('Info') + ' I701', this.translate.instant('err701FileNotFound'), { closeButton: true });
                                            console.error('Download failed:', error);
                                        });
    }

    landlord: Landlord;
    tenant: Tenant;
    rcontract: RentalContract;
    monthlyPayment: number;
    monthlyAdminPayment: number;

    
    ngOnInit(): void {
        this.id = Number(this.route.snapshot.paramMap.get("id"));
        this.getById(this.id);
        this.loadTransactions(this.id);
        this.loadFlats();
        this.roles = Object.keys(Role);
        this.meterTypes = Object.keys(MeterType);
        this.meterCategories = Object.keys(MeterCategory);  
        this.agreementType = Object.keys(AgreementType);  
        this.contractStatus = Object.keys(RentalContractStatus);
        this.invoiceStatus = Object.keys(InvoiceStatus);
        this.expensesStatus = Object.keys(ExpensesStatus);
        this.expensesType = Object.keys(ExpensesType);
        this.transactionStatus = Object.keys(TransactionStatus);
        this.SetUserRole();
    }

    private SetUserRole()
    {
        this.userRole = this.authService.getUserRole();
        this.userId = this.authService.getUserId();
        switch(this.userRole)
        {
            case Role.Admin:
                this.loadMeters(this.id);
                this.loadInspections(this.id);
                this.loadEquipments(this.id);
                this.loadAgreements(this.id);
                this.loadContracts(this.id);
                this.loadInvoices(this.id);
                this.loadExpenses(this.id);
                this.loadLandlords();
                this.loadTenants();
                this.loadEmployees();
                this.viewType = 'admin';
                break;
            case Role.Employee:
                this.loadMeters(this.id);
                this.loadInspections(this.id);
                this.loadEquipments(this.id);
                this.loadAgreements(this.id);
                this.loadContracts(this.id);
                this.loadInvoices(this.id);
                this.loadExpenses(this.id);
                this.loadLandlords();
                this.loadTenants();
                this.loadEmployees();
                this.viewType = 'employee';
                break;
            case Role.Landlord:
                this.loadMeters(this.id);
                this.loadEquipments(this.id);
                this.loadAgreements(this.id);
                this.loadContracts(this.id);
                this.loadInvoices(this.id);
                this.loadExpenses(this.id);
                this.viewType = 'landlord';
                break;
            case Role.Tenant:
                this.loadMeters(this.id);
                this.loadTenantContracts(this.id, this.userId);
                this.loadExpenses(this.id);
                this.viewType = 'tenant';
                break;
        }
    }


    private getById(id: number) {
        this.FlatService.getById(id).subscribe((data: Flat) => {
            this.item = data;
            this.currentLandlordId == this.item.landlordId;
        });
    }

    private loadMeters(id: number) {
        this.meterService.getAll(id).subscribe((data: Meter[]) => {
            this.meters = data;
            this.cdr.detectChanges();
        })
    }

    private loadInspections(id: number) {
        this.inspectionService.getAll(id).subscribe((data: Inspection[]) => {
            this.inspections = data;
            this.cdr.detectChanges();
        })
    }

    private loadEquipments(id: number) {
        this.equipmentService.getAll(id).subscribe((data: Equipment[]) => {
            this.equipments = data;
            this.cdr.detectChanges();
        })
    }

    private loadAgreements(id: number){
        this.agreementService.getAll(id).subscribe((data: Agreement[]) => {
            this.agreements = data;
            this.cdr.detectChanges();
        })
    }
  

    private loadContracts(id: number){
        this.contractService.getAll(id).subscribe((data: RentalContract[]) => {
            this.contracts = data;
            this.rcontract = this.contracts.filter(x => x.status == RentalContractStatus.Active).lastItem;
            this.cdr.detectChanges();
        });
    }

    private loadTenantContracts(id: number, userId: number){
        this.contractService.getAll(id).subscribe((data: RentalContract[]) => {
            this.contracts = data.filter(x => x.tenant.id == userId && x.status == RentalContractStatus.Active);
            this.cdr.detectChanges();
        })
    }


    private loadLandlords() {
        this.landlordService.getAll().subscribe((data: Landlord[]) => {
            this.landlords = data;
            this.landlord = this.landlords.filter(x => x.id == this.item.landlordId).lastItem;
        })
    }

    private loadTenants() {
        this.tenantService.getAll().subscribe((data: Tenant[]) => {
            this.tenants = data;
        })
    }

    private loadEmployees() {
        this.employeeService.getAll().subscribe((data: Employee[]) => {
            this.employees = data;
        })
    }

    private loadTransactions(id: number) {
        this.transactionService.getAllByFlatId(id).subscribe((data: Transaction[]) => {
            this.transactions = data;
        })
    }

    //temp-version. expensesService.getAll(id) doesn't work on prod-server (issues with proxy)
    private loadExpenses(id: number) {
        /*this.expensesService.getAll(id).subscribe((data: Expenses[]) => {
            this.expenses = data;
            this.actualExpenses = this.expenses.filter(x => x.status == ExpensesStatus.Active);
            let internet = this.actualExpenses.filter( x => x.type == ExpensesType.InternetOther)?.lastItem?.value || 0;
            let admin = this.actualExpenses.filter( x => x.type == ExpensesType.Administrations)?.lastItem?.value || 0;
            let utilities = this.actualExpenses.filter( x => x.type == ExpensesType.Utilities)?.lastItem?.value || 0;
            let garage = this.actualExpenses.filter( x => x.type == ExpensesType.Garage)?.lastItem?.value || 0;
            let rent =  this.actualExpenses.filter( x => x.type == ExpensesType.Rent)?.lastItem?.value || 0;
            this.monthlyAdminPayment = garage + utilities + admin + internet;
            this.monthlyPayment = rent + this.monthlyAdminPayment;
            this.cdr.detectChanges();
        })*/
        this.FlatService.getById(id).subscribe((data: Flat) => {
            this.expenses = data.expenses;
            this.actualExpenses = this.expenses.filter(x => x.status == ExpensesStatus.Active);
            let internet = this.actualExpenses.filter( x => x.type == ExpensesType.InternetOther)?.lastItem?.value || 0;
            let admin = this.actualExpenses.filter( x => x.type == ExpensesType.Administrations)?.lastItem?.value || 0;
            let utilities = this.actualExpenses.filter( x => x.type == ExpensesType.Utilities)?.lastItem?.value || 0;
            let garage = this.actualExpenses.filter( x => x.type == ExpensesType.Garage)?.lastItem?.value || 0;
            let rent =  this.actualExpenses.filter( x => x.type == ExpensesType.Rent)?.lastItem?.value || 0;
            this.monthlyAdminPayment = garage + utilities + admin + internet;
            this.monthlyPayment = rent + this.monthlyAdminPayment;
            this.cdr.detectChanges();
        });
    }

    private loadInvoices(id: number) {
        this.invoiceService.getAll(id).subscribe((data: Invoice[]) => {
            this.invoices = data;
            this.balance = this.invoices.map(x => x.value).reduce((sum, invoice) => sum + invoice, 0);
            this.cdr.detectChanges();
        })
    }

    update(): void {
        let updatedModel = this.adapter.adapt(this.item);
        updatedModel.yearBuilt = new Date(updatedModel.yearBuilt, 1, 1, 12, 0, 0, 0).valueOf() / 1000;
        console.log(updatedModel);
        this.FlatService.update(updatedModel).subscribe(data => {
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private deleteAgreement(id: number): void {
        this.agreementService.delete(id).subscribe(data => {
            this.loadAgreements(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private deleteEquipments(id: number): void {
        this.equipmentService.delete(id).subscribe(data => {
            this.loadEquipments(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private deleteInspection(id: number): void {
        this.inspectionService.delete(id).subscribe(data => {
            this.loadInspections(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private deleteMeter(id: number): void {
        this.meterService.delete(id).subscribe(data => {
            this.loadMeters(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private deleteContract(id: number): void {
        this.contractService.delete(id).subscribe(data => {
            this.loadContracts(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private deleteExpenses(id: number): void {
        this.expensesService.delete(id).subscribe(data => {
            this.loadExpenses(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private deleteInvoice(id: number): void {
        this.invoiceService.delete(id).subscribe(data => {
            this.loadInvoices(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private deleteTransaction(id: number): void {
        this.transactionService.delete(id).subscribe(data => {
            this.loadTransactions(this.id);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    viewPageLandlord(id:number){
        this.router.navigate(['/landlords/'+ id]);
    }

    viewPageTenant(id:number){
        this.router.navigate(['/tenants/'+ id]);
    }

    viewFilePage(id: number) {
        this.router.navigate(['/exportFiles/'+ id]);
    }

    confirmDelete(id: number, numberItem: number) {
        swal.fire({
            title: this.translate.instant('DeleteIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                switch(numberItem) {
                    case 1:
                        this.deleteEquipments(id);
                        break;
                    case 2:
                        this.deleteAgreement(id);
                        break;
                    case 3:
                        this.deleteInspection(id);
                        break;
                    case 4:
                        this.deleteMeter(id);
                        break;
                    case 5:
                        this.deleteContract(id);
                        break;
                    case 6:
                        this.deleteInvoice(id);
                        break;
                    case 7:
                        this.deleteExpenses(id);
                        break;
                    case 8:
                        this.deleteTransaction(id);
                        break;
                }
            }
        })
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/flats/allflats']);
            }
        })

    }

}