import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExportFilesService } from './export-files.service';
import { ExportFiles } from 'app/models/exportFiles/exportFiles';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { ExportFilesFull } from 'app/models/exportFiles/exportFilesFull';
import { TransactionService } from 'app/transaction/transaction.service';
import { TransactionStatus } from 'app/models/enums/transactionStatus';
import { FileService } from 'app/flat/file.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Transaction } from 'app/models/transaction/transaction';
import { Flat } from 'app/models/flat/flat';
import { FlatService } from 'app/flat/flat.service';
import { TransactionUpdateAdapter } from 'app/models/transaction/transactionUpdate';


@Component({
  selector: 'app-order',
  templateUrl: './files-transactions.component.html',
  styleUrls: ['./export-files.component.scss'],
  providers: [ExportFilesService, TransactionService, FileService, FlatService]
})
export class FileTransactionsComponent implements OnInit {

item: ExportFilesFull;
temp = [];
id: number;
countErrors: number;
itemEdit: Transaction;
transactionStatus: string[];
flats: Flat[];

@ViewChild('contentEdit') contentEdit: TemplateRef<any>;

constructor(private exportFilesService: ExportFilesService,
            private transactionService: TransactionService,
            private adapterTransaction: TransactionUpdateAdapter,
            private fileService: FileService,
            private flatService: FlatService,
            private router: Router,
            private route: ActivatedRoute,
            private modalService: NgbModal,
            private translate: TranslateService,
            private toastr: ToastrService,
            private cdr: ChangeDetectorRef
) { }

ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.loadData();
    this.loadFlats();
    this.transactionStatus = Object.keys(TransactionStatus);
}

private loadFlats(): void {
    this.flatService.getAll().subscribe((data: Flat[]) => {
        this.flats = data;
    })
}

updateTransaction(modal: NgbModalRef): void {
    let updatedTransaction = this.adapterTransaction.adapt(this.itemEdit);
    this.transactionService.update(updatedTransaction).subscribe(data => {
        this.loadData();
        this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        modal.close();
    })
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
            const sum = d.sum ? d.sum.toString() : '';
            const description = d.description ? d.description.toLowerCase() : '';
            const internalId = d.internalId ? d.internalId.toString() : '';
            const id = d.id ? d.id.toString() : '';

        return sum.indexOf(val) !== -1 ||
               description.indexOf(val) !== -1 ||
               internalId.indexOf(val) !== -1 ||
               id.indexOf(val) !== -1 || !val;
        });

    this.item.transactions = temp;
}

private loadData(): void {
    this.exportFilesService.getAllById(this.id).subscribe((data: ExportFilesFull) => {
        this.temp = [...data.transactions];
        this.item = data;
        this.countErrors = data.transactions.filter(x => x.status == TransactionStatus.Error).length;
        this.cdr.detectChanges();
    })
}


viewPage(id: number) {
    this.router.navigate(['/exportFiless/'+ id]);
}

viewFlatPage(id: number) {
    this.router.navigate(['/flats/'+ id]);
}

private delete(id: number): void {
    this.transactionService.delete(id).subscribe(data => {
        this.loadData();
        this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
    })
}

openTransactionEditDialog(id:number): void {
    this.modalService.open(this.contentEdit);
    this.getById(id);
}

private getById(id: number): void {
    this.transactionService.getById(id).subscribe((data: Transaction) => {
        this.itemEdit = data;
    });
}

downloadFile(itemName: string, fileName: string, id: number): void {
    this.fileService.getFile(itemName, fileName, id)
        .subscribe((blob: Blob) => { 
            this.fileService.saveFile(blob, fileName); }, error => {
                this.toastr.info(this.translate.instant('Info') + ' I701', this.translate.instant('err701FileNotFound'), { closeButton: true });
                                        console.error('Download failed:', error);
                                    });
}

confirmDelete(id: number) {
    swal.fire({
        title: this.translate.instant('DeleteIt?'),
        text: this.translate.instant('AbleRevert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('Cancel'),
        confirmButtonText: this.translate.instant('Yes')
    }).then((result) => {
        if (result.value) {
            this.delete(id);
        }
    })
}


}
