import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ExportFilesService } from './export-files.service';
import { ExportFiles } from 'app/models/exportFiles/exportFiles';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { FileService } from 'app/flat/file.service';


@Component({
  selector: 'app-order',
  templateUrl: './export-files.component.html',
  styleUrls: ['./export-files.component.scss'],
  providers: [ExportFilesService, FileService]
})
export class ExportFilesComponent implements OnInit {

  items: ExportFiles[];
  temp = [];

  @ViewChild('fileInput') fileInput: ElementRef;
  selectedFile: File | null = null;

  constructor(private exportFilesService: ExportFilesService,
            private fileService: FileService,
              private router: Router,
              private translate: TranslateService,
              private toastr: ToastrService
  ) { }

ngOnInit(): void {
    this.loadData();
}

onClickUpload() {
    this.fileInput.nativeElement.click();
}

onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.uploadFile();
    }
}

uploadFile() {
    if (this.selectedFile) {
      this.fileService.uploadFile(this.selectedFile).subscribe({
        next: (response) => {
            this.loadData();
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        },
        error: (error) => {
          console.error('File upload failed:', error);
        }
      });
    }
}

updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
        return  d.fileName.toLowerCase().indexOf(val) !== -1 ||
                d.countAll.toString().indexOf(val) !== -1 ||
                d.countSuccessful.toString().indexOf(val) !== -1 || 
                d.hash.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.items = temp;
}

private loadData(): void {
    this.exportFilesService.getAll().subscribe((data: ExportFiles[]) => {
        this.temp = [...data];
        this.items = data;
    })
}

private delete(id: number): void {
    this.exportFilesService.delete(id).subscribe(data => {
        this.loadData();
        this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
    })
}

viewPage(id: number) {
    this.router.navigate(['/exportFiles/'+ id]);
}

viewPageTransactions() {
    this.router.navigate(['/transactions/alltransactions']);
}


confirmDelete(id: number) {
    swal.fire({
        title: this.translate.instant('DeleteIt?'),
        text: this.translate.instant('AbleRevert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('Cancel'),
        confirmButtonText: this.translate.instant('Yes')
    }).then((result) => {
        if (result.value) {
            this.delete(id);
        }
    })
}


}
