import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { InvoiceStatus } from "../enums/invoiceStatus";
import { Flat } from "../flat/flat";

export class InvoiceUpdate {
    constructor(
        public id: number,
        public status: InvoiceStatus,
        public title?: string,
        public value?: number,
        public description?: string,
        public comment?: string,
        public endTime?: number,
        public flat?: Flat,
        public files?: File[]
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class InvoiceAdapterUpdate implements Adapter<InvoiceUpdate> {
    adapt(item: any): InvoiceUpdate {
        return new InvoiceUpdate(
            item.id,
            item.status,
            item.title,
            item.value,
            item.description,
            item.comment,
            item.endTime,
            item.flat,
            item.files || []
        )
    }

}