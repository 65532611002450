import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import swal from 'sweetalert2';
import { DocumentService } from "./document.service";
import { Document } from "app/models/document/document";
import { FileService } from "app/flat/file.service";
import { AuthService } from "app/shared/auth/auth.service";

@Component({
    selector: 'app-order',
    templateUrl: './document.component.html',
    providers: [DocumentService, FileService]
})
export class DocumentComponent implements OnInit {
    
    items: Document[];
    temp = [];

    constructor(private documentService: DocumentService,
                private fileService: FileService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService,
                private toastr: ToastrService,
                private cdr: ChangeDetectorRef,
                private authService: AuthService,) {

    }

    ngOnInit(): void {
        if (!this.authService.CurrentUserValue) {
            this.router.navigate(['/pages/login']);
        }
        this.loadData();
    }

    downloadArchive(itemName: string, id: number): void {
        this.fileService.getArhive(itemName, id)
            .subscribe((blob: Blob) => 
            { 
                this.fileService.saveFile(blob, 'archive'); 
            }, error => {
                this.toastr.info(this.translate.instant('Info') + ' I701', this.translate.instant('err701FileNotFound'), { closeButton: true });
                console.error('Download failed:', error);
             });
    }

    private loadData(): void {
        this.documentService.getAll().subscribe((data: Document[]) => {
            this.temp = [...data];
            this.items = data;
            this.cdr.detectChanges();
        })
    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            return  d.title.toLowerCase().indexOf(val) !== -1 ||
                    d.description.toLowerCase().indexOf(val) !== -1 || 
                    d.createTime.toISOString().toLowerCase().indexOf(val) !== -1 || 
                    d.comment.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.items = temp;
    }

    private delete(id: number): void {
        this.documentService.delete(id).subscribe(data => {
            this.loadData();
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    viewPage(id: number) {
        this.router.navigate(['/documents/'+ id]);
    }

    confirmDelete(id: number) {
        swal.fire({
            title: this.translate.instant('DeleteIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.delete(id);
            }
        })
    }
    
}