

<section  id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'DocumentManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'DocumentManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-header pb-2">
                        <h4 class="card-title" id="horz-layout-colored-controls">{{'UpdateDocumentTitle' | translate}} (ID: {{item.id}})</h4>
                    </div>
                    <div class="card-body">
                        <div class="mt-2">
                            <form class="form" #form="ngForm" (ngSubmit)="update(form)">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <p class="mb-0">{{'UpdateDocumentDescription' | translate}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Title' | translate}} *</label>
                                                <input [(ngModel)]="item.title" 
                                                    placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                    name="title" #title="ngModel" class="form-control"
                                                    maxlength="50" required>
                                                <div [hidden]="title.valid || title.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                        </div>    
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Description' | translate}} </label>
                                                <textarea [(ngModel)]="item.description" id="bdescription"
                                                    rows="3" class="form-control" name="description"
                                                    #description="ngModel" maxlength="2000"></textarea>
                                            </div>
                                        </div> 
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{'Comment' | translate}} </label>
                                                <textarea [(ngModel)]="item.comment" id="bdescription" 
                                                    rows="3" class="form-control" name="comment"
                                                    #comment="ngModel" maxlength="2000"></textarea>
                                            </div>
                                        </div>
                                       
                                        <div class="col-md-3">
                                            <div class="form-group" *ngIf="item.fileNames?.length > 0">
                                                <label>{{'UploadedFiles' | translate}}:</label>
                                                <ul>
                                                    <li *ngFor="let fileName of item.fileNames">
                                                      <span class="fileName cursor-pointer" 
                                                            (click)="openModal(filePreviewModal1, 'documents', fileName, item.id)">  <!--openFilePreview('documents', fileName, item.id)-->
                                                        {{ fileName }}
                                                      </span>
                                                      <a class="danger p-0" data-original-title="" title="download" 
                                                            (click)="downloadFile('documents', fileName, item.id)">
                                                            <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                      </a>
                                                      <a class="danger p-0" data-original-title="" title="delete" 
                                                          (click)="removeFile('documents', fileName, item.id)">
                                                          <i class="ft-trash cursor-pointer mr-2"></i>
                                                      </a>
                                                    </li>
                                                </ul>        
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Files' | translate}}: *</label>
                                                <input id="files" type="file" (change)="handleFileSelection($event, item)" multiple />
                                                <div *ngIf="isFileRequired && !isFileSelected" class="text-danger">
                                                    {{'FieldRequired' | translate}}
                                                </div>
                                            </div>
                                        </div>                
                                    </div>
                                    <div class="row">   
                                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                            <button type="submit" [disabled]="isFileRequired && !isFileSelected" 
                                                class="btn btn-primary mb-2 mb-sm-0 mr-sm-2"> 
                                                {{'Save' | translate}}
                                            </button>
                                            <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                {{'Cancel' | translate}}
                                            </button> 
                                        </div>
                                    </div> 
                                   
                                </div>
                            </form>
                          
                            <ng-template #filePreviewModal1 let-modal>
                                <div class="modal-body">
                                  <iframe [src]="fileUrl" width="100%" height="600px"></iframe>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">{{'Close' | translate}}</button>
                                </div>
                            </ng-template>

                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>