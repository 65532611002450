import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';
import { DocumentAdapter } from "app/models/document/document";
import { DocumentCreate } from "app/models/document/documentCreate";
import { DocumentUpdate } from "app/models/document/documentUpdate";


@Injectable()
export class DocumentService {
    private url = environment.baseApiurl + "/Document";

    constructor(private http: HttpClient, 
                private adapter: DocumentAdapter) {
    }

    getAll() {
        return this.http.get(this.url + '/getAll')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getById(id: number) {
        return this.http.get(this.url + '/getById?id=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    create(newItem: DocumentCreate) {
        const formData: FormData = new FormData();     
        for (const key in newItem) {
            if (newItem.hasOwnProperty(key)) {
                formData.append(key, (newItem as any)[key]);
            }
        }

        newItem.files.forEach(file => {
          formData.append('files', file, file.name);
        });

        return this.http.post(this.url + '/create', formData);
    }

    update(updatedItem: DocumentUpdate) {
        const filteredItem = this.filterNullValues(updatedItem);
    
        const formData: FormData = new FormData();
        for (const key in filteredItem) {
            if (filteredItem.hasOwnProperty(key)) {
                formData.append(key, (filteredItem as any)[key]);
            }
        }
    
        if (filteredItem.files) {
            filteredItem.files.forEach(file => {
                formData.append('files', file, file.name);
            });
        }
    
        return this.http.put(this.url + '/update', formData);
    }

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }

    //replace it to a common file
    private filterNullValues(item: any): any {
        const filteredItem = { ...item };
        for (const key in filteredItem) {
            if (filteredItem[key] === null || filteredItem[key] === undefined) {
                delete filteredItem[key];
            }
        }
        return filteredItem;
    }
}

