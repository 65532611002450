import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { FlatService } from "./flat.service";
import { FlatCreate } from "app/models/flat/flatCreate";
import { LandlordService } from "app/landlord/landlord.service";
import { Landlord } from "app/models/landlord/landlord";
import { NgForm } from "@angular/forms";


@Component({
    selector: 'app-root',
    templateUrl: './add-flat.component.html',
    providers: [FlatService, LandlordService]
})
export class AddFlatComponent implements OnInit {
    
    item: FlatCreate = new FlatCreate();
    roles: string[];
    landlords: Landlord[];

    formSubmitted = false;

    constructor(private FlatService: FlatService,
                private landlordService: LandlordService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        this.loadLandlords();
    }
    
    create(form: NgForm): void {
        this.formSubmitted = true;
        if (form.valid) {
            this.item.yearBuilt = new Date(this.item.yearBuilt, 1, 1, 12, 0, 0, 0).valueOf() / 1000;
            this.item.deposit = 0;
            this.item.accountNumber2 = "0";
            this.item.accountNumber3 = "0";
            this.FlatService.create(this.item).subscribe(data => {
                this.router.navigate(['/flats/allflats']);
            })
        }
    }

    private loadLandlords() {
        this.landlordService.getAll().subscribe((data: Landlord[]) => {
            this.landlords = data;
        })
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/flats/allflats']);
            }
        })
    }

}