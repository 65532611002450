export enum AgreementType {
    InitialProtocolTenant = "InitialProtocolTenant",
    FinalProtocolTenant = "FinalProtocolTenant",
    PowerAttorneyAgency = "PowerAttorneyAgency",
    //PowerAttorneyRenting = "PowerAttorneyRenting",
    InsuranceOCNajemcy = "InsuranceOCNajemcy",
    InitialProtocolLandlord = "InitialProtocolLandlord",
    FinalProtocolLandlord = "FinalProtocolLandlord",
    AgreementAgency = "AgreementAgency",
    Insurance = 'Insurance',
    Other = "Other"
}