import { Adapter } from "app/core/interfaces/adapter";
import { AgreementType } from "../enums/agreementType";
import { Injectable } from "@angular/core";

export class AgreementCreate{
    constructor(
        public files: File[] = [],
        public title?: string,
        public description?: string,
        public type?: AgreementType,
        public startDate?: number,
        public endDate?: number,
        public flatId?: number
    ){}
}

@Injectable({
providedIn: 'root'
})
export class AgreementCreateAdapter implements Adapter<AgreementCreate> {
    adapt(item: any): AgreementCreate {
        let convertedStartDate = item.startDate ? new Date(item.startDate).getTime() / 1000 : null;
        let convertedEndDate = item.endDate ? new Date(item.endDate).valueOf() / 1000 : null;

        return new AgreementCreate(
            item.files,
            item.title,
            item.description,
            item.type,
            convertedStartDate,
            convertedEndDate,
            item.flatId
        )
    }
}