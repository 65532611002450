import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { AgreementCreate } from "app/models/agreement/agreementCreate";
import { AgreementUpdate } from "app/models/agreement/agreementUpdate";
import { AgreementAdapter } from "app/models/agreement/agreement";

@Injectable()
export class AgreementService {
    private url = environment.baseApiurl + "/agreement";

    constructor(private http: HttpClient, 
                private adapter: AgreementAdapter) {
    }

    getAll(flatId: number) {
        return this.http.get(this.url + '/GetAllByFlatId?flatId=' + flatId)
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getById(id: number) {
        return this.http.get(this.url + '/getById?flatId=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    create(newItem: AgreementCreate) {
        const formData: FormData = new FormData();     
        for (const key in newItem) {
            if (newItem.hasOwnProperty(key)) {
                const value = (newItem as any)[key];
                if (value !== null && value !== undefined) {
                    formData.append(key, value);
                }
            }
        }
    
        newItem.files.forEach(file => {
            formData.append('files', file, file.name);
        });
    
        return this.http.post(this.url + '/create', formData);
    }

    update(updatedItem: AgreementUpdate) {
        // Отфильтруем null/undefined поля
        const filteredItem = this.filterNullValues(updatedItem);
    
        const formData: FormData = new FormData();
        for (const key in filteredItem) {
            if (filteredItem.hasOwnProperty(key)) {
                formData.append(key, (filteredItem as any)[key]);
            }
        }
    
        if (filteredItem.files) {
            filteredItem.files.forEach(file => {
                formData.append('files', file, file.name);
            });
        }
    
        return this.http.put(this.url + '/update', formData);
    }

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }

    private filterNullValues(item: any): any {
        const filteredItem = { ...item };
        for (const key in filteredItem) {
            if (filteredItem[key] === null || filteredItem[key] === undefined) {
                delete filteredItem[key];
            }
        }
        return filteredItem;
    }
}