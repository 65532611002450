export enum Role {
    Admin = 'Admin',
    Employee = 'Employee',
    Tenant = 'Tenant',
    Landlord = 'Landlord'
}

export const rolePermissions = {
    [Role.Admin]: ['Flats', 'Landlords', 'Tenants', 'Employees', 'Documents', 'Transactions', 'Exports'],
    [Role.Tenant]: ['Flats'],
    [Role.Landlord]: ['Flats'],
  };