// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false, 
  companyName: "HMGT",
  //baseApiurl: "https://hm.dv.host151020241219.of.by/api"  
  //baseApiurl: "https://localhost:7282/api" 
  baseApiurl: "https://hmgt.metrohousedev.com/api"
};
