import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { DocumentService } from "./document.service";
import { DocumentUpdate, DocumentUpdateAdapter } from "app/models/document/documentUpdate";
import { Document } from "app/models/document/document";
import { FileService } from "app/flat/file.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NgModel } from "@angular/forms";


@Component( {
    selector: 'app-root',
    styleUrls: ['./document.component.css'],
    templateUrl: './profile-document.component.html',
    providers: [DocumentService, FileService]
})
export class ProfileDocumentComponent implements OnInit {
    
    item: Document;
    selectedFiles: File[] = [];
    isFileSelected = false;
    isFileRequired = false;
    id: number;
    fileUrl: any;

    formSubmitted = false;

    constructor(private documentService: DocumentService,
                private fileService: FileService,
                private route: ActivatedRoute,
                private router: Router,
                private adapter: DocumentUpdateAdapter,
                private toastr: ToastrService,
                private cdr: ChangeDetectorRef,
                private translate: TranslateService,
                private modalService: NgbModal,
                private sanitizer: DomSanitizer ) {

    }

    openModal(modal: any, itemName: string, fileName: string, id: number) {
        this.fileService.getFile(itemName, fileName, id).subscribe(blob => {
            const url = window.URL.createObjectURL(blob);
            this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url) as string;;
            this.modalService.open(modal, { size: 'xl', centered: true });
         });
    }
    
    ngOnInit(): void {
        this.id = Number(this.route.snapshot.paramMap.get("id"));
        this.getById(this.id);
    }


    private getById(id: number) {
        this.documentService.getById(id).subscribe((data: Document) => {
            this.item = data;
            this.isFileRequired = this.item.fileNames?.length === 0;
            this.cdr.detectChanges();
        })
    }

    update(form: NgModel): void {
        this.formSubmitted = true;
        if (form.valid) {
            let updatedModel = this.adapter.adapt(this.item);
            this.documentService.update(updatedModel).subscribe(data => {
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                this.getById(this.id);
            })
        }
    }

    handleFileSelection(event: any, item: any) {
        if (event.target.files) {
            item.files = Array.from(event.target.files);
            this.isFileSelected = true; 
        } else {
            item.files = [];
            this.isFileSelected = false; 
        }
    }

    downloadFile(itemName: string, fileName: string, id: number): void {
        this.fileService.getFile(itemName, fileName, id)
            .subscribe((blob: Blob) => { 
                this.fileService.saveFile(blob, fileName); }, error => {
                    this.toastr.info(this.translate.instant('Info') + ' I701', this.translate.instant('err701FileNotFound'), { closeButton: true });
                                            console.error('Download failed:', error);
                                        });
    }

    downloadArchive(itemName: string, id: number): void {
        this.fileService.getArhive(itemName, id)
            .subscribe((blob: Blob) => { this.fileService.saveFile(blob, 'archive'); }, error => {
                this.toastr.info(this.translate.instant('Info') + ' I701', this.translate.instant('err701FileNotFound'), { closeButton: true });
                                            console.error('Download failed:', error);
                                        });
    }

    /* --- separate page
    openFilePreview(itemName: string, fileName: string, id: number) {
        this.fileService.getFile(itemName, fileName, id).subscribe(blob => {
          const url = window.URL.createObjectURL(blob);
          const newWindow = window.open('', '_blank');
      
          if (newWindow) {
            newWindow.document.open();
            newWindow.document.write(`
              <html>
                <head><title>${fileName}</title></head>
                <body>
                  <iframe src="${url}" style="width:100%; height:100%; border:none;" frameborder="0"></iframe>
                </body>
              </html>
            `);
            newWindow.document.close(); 
          } else {
            console.error('Unable to open new window for file preview');
          }
    
          newWindow.onload = () => {
            window.URL.revokeObjectURL(url);
          };
        }, error => {
          console.error('File preview failed', error);
        });
    }*/
   


    removeFile(itemName: string, fileName: string, id: number): void {
        this.fileService.deleteFile(itemName, fileName, id).subscribe(() => {
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
            this.getById(this.id);
            this.cdr.detectChanges();
        });
    }


    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/documents/alldocuments']);
            }
        })
    }

}