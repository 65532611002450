import { ExpensesStatus } from "../enums/expensesStatus";
import { ExpensesType } from "../enums/expensesType";

export class ExpensesCreate {
    constructor(
        public files: File[] = [],
        public type?: ExpensesType,
        public status?: ExpensesStatus,
        public value?: number,
        public startDate?: number,
        public accountNumber ?: string,
        public comment: string = "",
        public flatId?: number
    ){}
}