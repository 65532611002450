import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";

import { AuthGuard } from './shared/auth/auth-guard.service';
import { UserComponent } from './user/user.component';
import { AddUserComponent } from './user/add-user.component';
import { LoginPageComponent } from './pages/content-pages/login/login-page.component';

const appRoutes: Routes = [
  /*{
    path: '',
    redirectTo: 'pages/login',
    pathMatch: 'full',
  },*/
  {
    path: '',
    redirectTo: 'flats/allflats',
    pathMatch: 'full', // перенаправляем с корня на flats/allflats
  },
  { path: '', 
    component: FullLayoutComponent, 
    data: { title: 'full Views' }, 
    children: Full_ROUTES, 
    canActivate: [AuthGuard] },
  { 
    path: '', 
    component: ContentLayoutComponent, 
    data: { title: 'content Views' }, 
    children: CONTENT_ROUTES, 
    canActivate: [AuthGuard] },
  {
    path: 'flats',
    loadChildren: () => import('./flat/flat.module').then(m => m.FlatModule),
    canActivate: [AuthGuard] 
  },
  {
    path: 'documents',
    loadChildren: () => import('./document/document.module').then(m => m.DocumentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages/login',
    component: LoginPageComponent, 
  },
  {
    path: '**',               //path for any link. Warning! it path must be last
    redirectTo: 'pages/error'
  }
];

@NgModule({
  //imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
